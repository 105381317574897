import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourseAsync } from '../redux/reducer/courses/courseSlice';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import './page.css'
import Sidebar from './Sidebar';
import {Modal,Card, Typography, TextField, Button,Select,MenuItem,FormControl } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import ReportIcon from '@mui/icons-material/Report';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
//APIs
import { createCourseAsync,deleteCourseAsync,updateCourseAsync } from "../redux/reducer/courses/courseSlice";
import { fetchCategoryAsync,updateCategoryAsync,} from '../redux/reducer/category/categorySlice';
const Courses = () => {

    const { appId } = useParams();
    const dispatch = useDispatch();
  
    const [open, setOpen] = useState(false);
    const [code, setCode] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    
    const categories = useSelector((state) => state.category.list);
    const courses = useSelector((state) => state.course.list);
    const loading = useSelector((state) => state.course.loading);
    const [saved, setSaved] = useState(false); // New state for saved
    const [saving, setSaving] = useState(false); // New state for saved



    useEffect(() => {
      dispatch(fetchCourseAsync(appId));
    }, [dispatch, appId]);

    useEffect(() => {
      dispatch(fetchCategoryAsync(appId));
    }, [dispatch, appId]);
  
    if (!courses) {
      return <Box><CircularProgress/> Loading...</Box>;
    }

    const handleImageChange = (event) => {
      setSelectedImage(event.target.files[0]);
    };

    const handleOpen = () => {
      setOpen(true);
      setSaved(false)
    };
    const openEditModal = (course) => {
      setIsEditModalOpen(true);
      setSelectedCourse(course)
    };
  
    // Function to close the edit modal
    const closeEditModal = () => {
      setSelectedCourse(null)
      setIsEditModalOpen(false);
    };
  
  
    const handleClose = () => {
      setOpen(false);
      setSaved(false)
      setCode('')
      setSelectedImage(null)
      setName('')
    };
    const handleOpenDeleteModal = (course) => {
      setSelectedCourse(course);
      setOpenDeleteModal(true);
    };
  
    const handleCloseDeleteModal = () => {
      setOpenDeleteModal(false);
    };

    const chooseCategory = () =>{
      setSelectedCategory(category._id)
      console.log(category._id)
      console.log(category)
    }
    const handleAdd = async (e) => {     
      e.preventDefault()
      if (selectedImage) {
        const formData = new FormData();
        formData.append('file', selectedImage);
        formData.append('upload_preset', 'istudy');
        try {
          const response = await axios.post(
            'https://api.cloudinary.com/v1_1/istudyapp/image/upload',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
          // Handle the response from Cloudinary after successful upload
          const image = response.data.secure_url
          console.log(image)
          const courseData = {
            code,name,image
          } 
          dispatch(createCourseAsync(appId,category._id, courseData))
          .then(()=>{
            setSaved(true)
            dispatch(fetchCourseAsync(appId));
          })
          setSaved(false)
        } catch (error) {
          console.error(error);
  
        }
      }else{
          const courseData = {
            code,name
          } 
          dispatch(createCourseAsync(appId,category._id,courseData))
          .then(()=>{
            setSaved(true)
            dispatch(fetchCourseAsync(appId));
           console.log(courseData)
          })
          
      }
     
      dispatch(fetchCourseAsync(appId));
      
    };

      //Edit a category
  const handleUpdateCourse = async (event) =>{
    event.preventDefault();

    if(selectedCourse){
      console.log(selectedCourse)
      dispatch(updateCourseAsync({appId,courseId:selectedCourse._id,courseData:selectedCourse}))
      .then((updatedCourse) => {
        if (updatedCourse) {
          // Update the 'notes' state variable with the updated note data
          setSelectedCourse(updatedCourse);
          dispatch(fetchCourseAsync(appId))
          setSaved(true);
        }
      })
      .catch((error) => {
        console.error(error);
        // Handle error if necessary
      });
      dispatch(fetchCourseAsync(appId))
    }
    dispatch(fetchCourseAsync(appId))
  }
    //Delete a course
  const handleDeleteCourse = () => {
      if (selectedCourse) {
        dispatch(deleteCourseAsync({appId,courseId:selectedCourse}));
        console.log(`deleted ${selectedCourse}`)
        dispatch(fetchCourseAsync( appId));
        handleCloseDeleteModal();
  
      }
      dispatch(fetchCourseAsync(appId));
    };
   
  
  return (
    <Box>
        <Sidebar />
        <Box>
          <Typography variant='h5' fontWeight="700">Courses</Typography>
          <Button align="center" style={{marginTop:"0.3rem",marginBottom:"1rem"}} variant="contained" color="secondary" onClick={handleOpen} >
            <AddIcon/> Create Course
          </Button>
          <Modal open={open} onClose={handleClose}>
            <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, backgroundColor: 'white', borderRadius: 8, padding: 16 }}>
             <form enctype="multipart/form-data"onSubmit={handleAdd} >
             <Typography variant="h6">Create Course</Typography>
              <TextField required label="Code" name="code" fullWidth margin="normal" value={code}  onChange={(e) => setCode(e.target.value)} />
              <TextField required label="Name" name="name" fullWidth margin="normal" value={name}  onChange={(e) => setName(e.target.value)} />

              <Typography>Course Category</Typography>
              <Select
                id="answer"
                value={category} style={{marginTop:"1rem",marginBottom:"1rem",minWidth:"100PX"}}
                onChange={(e) => setCategory(e.target.value)}
              >
                {categories.map((category, index) => (
                  <MenuItem key={index} value={category} onClick={chooseCategory}>
                    {category.category}
                  </MenuItem>
                ))}
              </Select>

              <input type="file" accept="image/*"  style={{margin:"0.8rem"}} onChange={handleImageChange} />
              <Box style={{display:"flex",gap:"6px",alignItems:"center",justifyContent:"center",paddingBottom:"1rem"}}>
              <Button variant="contained" type='submit' style={{background:"#540a61"}} >
              {loading ? 'Saving...' : saved ? 'Saved Successfully' : 'Save'}
              </Button>
              <Button variant='contained' onClick={handleClose} style={{background:"red"}}>Cancel</Button>
              </Box>
             </form>
            </Box>
          </Modal>
        </Box>
         
        <Card>        
        <TableContainer>
                  <Table >
                      <TableHead>
                        <TableRow style={{borderBottom:"1px solid #8a898f"}}>
                              <TableCell>S/N</TableCell>
                              <TableCell>Course Code</TableCell>
                              <TableCell>Course Title</TableCell>
                              <TableCell colSpan="2">Action</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                      {loading ? (
                        <TableRow>
                            <TableCell colSpan={4} align="center">
                              <CircularProgress /> {/* Display loader while fetching data */}
                            </TableCell>
                        </TableRow>
                        ) : (
                        courses.map((course,index) =>(
                        <TableRow key={course._id}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{course.code}</TableCell>
                            <TableCell>{course.name}</TableCell>
                            <TableCell>
                            <Button variant='contained' style={{background:"#2f68df"}} onClick={() => openEditModal(course)}><EditCalendarIcon /> </Button> &nbsp;
                              <Button variant='contained' style={{background:"red"}} onClick={() => handleOpenDeleteModal(course._id)}><DeleteForeverIcon background="red" /> </Button></TableCell>
                        </TableRow>
                          ))  
                        
                      )}</TableBody>
                    </Table>
               </TableContainer>
        </Card>

        {/**Edit */}
        {selectedCourse && (
        <Modal open={isEditModalOpen} onClose={closeEditModal}>
            <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, backgroundColor: 'white', borderRadius: 8, padding: 16}}>  
            <Typography variant="h6" style={{marginBottom:"0.5rem"}}>Edit Course</Typography>
            <TextField 
            value={selectedCourse.code} 
            label="Code"
            margin="normal"
            fullWidth
            onChange={(e) => setSelectedCourse({ ...selectedCourse, code: e.target.value })}
            />      
              <TextField 
            value={selectedCourse.name} 
            label="Name"
            margin="normal"
            fullWidth
            onChange={(e) => setSelectedCourse({ ...selectedCourse, name: e.target.value })}
            />   
           <Box style={{display:"flex",gap:"6px",alignItems:"center",justifyContent:"center",margin:"1rem"}}>
           <Button variant="contained" style={{background:"#540a61"}} onClick={handleUpdateCourse}>
                 {loading ? 'Saving...' : saved ? 'Saved Successfully' : 'Save'}
              </Button>
              <Button variant="contained" style={{background:"red",marginLeft:"1rem"}} onClick={closeEditModal}>
                  Cancel
              </Button>
           </Box>
            </Box>
        </Modal>
        )}

           {/**Delete Modal */}
    <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-confirmation-modal"
        aria-describedby="delete-confirmation-modal-description"
      >
        <Box style={{ 
          position: 'absolute', 
          top: '50%', left: '50%',
          transform: 'Translate(-50%, -50%)', 
          width: 400,
          backgroundColor: 'white', 
          borderRadius: 8, padding: 16 }}>
          <Box align="center">
            <ReportIcon/>
            <Typography fontSize="16px" fontWeight="600" id="delete-confirmation-modal-description">
              Are you sure you want to delete this course?
            </Typography>
          </Box>
          <Box style={{display:"flex",gap:"6px",alignItems:"center",justifyContent:"center",margin:"1rem"}}>
          <Button variant='contained' onClick={handleDeleteCourse}>Yes,Delete</Button>
          <Button variant='contained' color='secondary' onClick={handleCloseDeleteModal}>Cancel</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
    
};


export default Courses
