import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Table from "@mui/material/Table";
import TextField from '@mui/material/TextField';
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Sidebar from './Sidebar';
import { Modal, Card, Typography, Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import axios from 'axios';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { setError } from '../redux/reducer/information/informationSlice';
import ReportIcon from '@mui/icons-material/Report';
import CircularProgress from '@mui/material/CircularProgress';
// KaTeX dependency
import "katex/dist/katex.css";
// Quill dependency
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
// MathQuill dependency
import "./jquery";
import "@edtr-io/mathquill/build/mathquill.js";
import "@edtr-io/mathquill/build/mathquill.css";
// mathquill4quill include
import mathquill4quill from "mathquill4quill";
import "mathquill4quill/mathquill4quill.css";
import parse from 'html-react-parser';
import Latex from 'react-latex-next';


// Add these imports for the WYSIWYG editor
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg'
// API
import { fetchInformationAsync, deleteInformationAsync, updateInformationAsync, createInformationAsync } from '../redux/reducer/information/informationSlice';
import katex from "katex";
window.katex = katex;

const Informations = () => {
  const { appId } = useParams();
  const dispatch = useDispatch();

  const informations = useSelector((state) => state.information.list);
  const loading = useSelector((state) => state.information.loading);

  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [content, setContent] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedInformation, setSelectedInformation] = useState(null);
  const [title, setTitle] = useState('');
  const [special, setSpecial] = useState(false);
  const [saved, setSaved] = useState(false); // New state for saved
  const [saving, setSaving] = useState(false); // New state for saved
  const reactQuillRef = useRef(null);
  const [informationBody, setInformationBody] = useState('');
  // Add state variable for the WYSIWYG editor
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (reactQuillRef.current) {
      const enableMathQuillFormulaAuthoring = mathquill4quill({ Quill, katex });
      enableMathQuillFormulaAuthoring(reactQuillRef.current.editor, {
        displayHistory: true,
        operators: [
          ["\\pm", "\\pm"],
          ["\\sqrt{x}", "\\sqrt"],
          ["\\sqrt[3]{x}", "\\sqrt[3]{}"],
          ["\\sqrt[n]{x}", "\\nthroot"],
          ["\\frac{x}{y}", "\\frac"],
          ["\\sum^{s}_{x}{d}", "\\sum"],
          ["\\prod^{s}_{x}{d}", "\\prod"],
          ["\\coprod^{s}_{x}{d}", "\\coprod"],
          ["\\int^{s}_{x}{d}", "\\int"],
          ["\\binom{n}{k}", "\\binom"],
          ['E = mc^2', 'E = mc^2'],
          ['\\Delta x = \\frac{v_f - v_i}{t}', '\\Delta x = \\frac{v_f - v_i}{t}'],
          ['x = \\frac{-b \\pm \\sqrt{b^2 - 4ac}}{2a}', 'x = \\frac{-b \\pm \\sqrt{b^2 - 4ac}}{2a}'],
          ['\\vec{F} = m\\vec{a}', '\\vec{F} = m\\vec{a}'],
          ['\\sin^2{x} + \\cos^2{x} = 1', '\\sin^2{x} + \\cos^2{x} = 1'],
          ['\\frac{d}{dx}e^x = e^x', '\\frac{d}{dx}e^x = e^x'],
          ['\\int_{a}^{b} f(x) dx', '\\int_{a}^{b} f(x) dx'],
          ['\\ln{x}', '\\ln'],
          ["\\alpha", "\\alpha"],
          ["\\beta", "\\beta"],
          ["\\gamma", "\\gamma"],
          ["\\delta", "\\delta"],
          ["\\epsilon", "\\epsilon"],
          ['\\sin{x}', '\\sin'],
          ['\\cos{x}', '\\cos'],
          ['\\tan{x}', '\\tan'],
          ['\\cot{x}', '\\cot'],
          ['\\sec{x}', '\\sec'],
          ['\\csc{x}', '\\csc'],
          ['\\arcsin{x}', '\\arcsin'],
          ['\\arccos{x}', '\\arccos'],
          ['\\arctan{x}', '\\arctan'],
          ['\\sinh{x}', '\\sinh'],
          ['\\cosh{x}', '\\cosh'],
          ['\\tanh{x}', '\\tanh'],
          ['\\coth{x}', '\\coth'],
          ['\\sech{x}', '\\sech'],
          ['\\csch{x}', '\\csch'],
          ['\\arcsinh{x}', '\\arcsinh'],
          ['\\arccosh{x}', '\\arccosh'],
          ['\\arctanh{x}', '\\arctanh'],
          ['\\arccoth{x}', '\\arccoth'],
          ['\\arcsech{x}', '\\arcsech'],
          ['\\arccsch{x}', '\\arccsch']
        ],
        displayDeleteButtonOnHistory: true
      });
    }
  }, [reactQuillRef.current]);

  const handleOpen = () => {
    setOpen(true);
    setSaved(false);
  };

  const handleInformationChange = (newContent) => {
    setInformationBody(newContent);
  };

  const handleClose = () => {
    setOpen(false);
    setSaved(false);
    setEditorState(EditorState.createEmpty()); // Reset the editor content
  };
  const openEditModal = (information) => {
    setIsEditModalOpen(true);
    setSelectedInformation(information)
  };
  // Function to close the edit modal
  const closeEditModal = () => {
    setSelectedInformation(null)
    setIsEditModalOpen(false);
  };
  const handleOpenDeleteModal = (information) => {
    setSelectedInformation(information);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };


  useEffect(() => {
    dispatch(fetchInformationAsync(appId));
  }, [dispatch, appId]);

  const handleAdd = async (e) => {
    e.preventDefault()

    if (!(title && informationBody)) {
      alert('Please input the required fields');
      return;
    }
    if (selectedImage) {
      const formData = new FormData();
      formData.append('file', selectedImage);
      formData.append('upload_preset', 'istudy');

      try {
        const response = await axios.post(
          'https://api.cloudinary.com/v1_1/istudyapp/image/upload',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        const Image = response.data.secure_url
        const informationData = {
          title,
          content: informationBody,
          image: Image,
          is_special: special,
        };

        dispatch(createInformationAsync(appId, informationData))
          .then(() => {
            setSaved(true);
            dispatch(fetchInformationAsync(appId));
          });
      } catch (error) {
        console.error(error);
      }
    }
    else {
      const informationData = {
        title,
        content: informationBody,
        is_special: special,
      };

      dispatch(createInformationAsync(appId, informationData))
        .then(() => {
          setSaved(true);
          dispatch(fetchInformationAsync(appId));
        });

      setSaved(false);
      setTitle('');
      setContent('');
    }
  }

  //Edit Topic
  const handleUpdateInformation = async (e) => {
    e.preventDefault();
    if (selectedInformation) {
      if (selectedImage) {
        const formData = new FormData();
        formData.append('file', selectedImage);
        formData.append('upload_preset', 'istudy');

        try {
          const response = await axios.post(
            'https://api.cloudinary.com/v1_1/istudyapp/image/upload',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );

          // Handle the response from Cloudinary after successful upload
          selectedInformation.image = response.data.secure_url
          dispatch(updateInformationAsync({ appId, informationId: selectedInformation._id, informationData: selectedInformation }))
            .then((updatedInformation) => {
              if (updatedInformation) {
                // Update the 'notes' state variable with the updated note data
                setSelectedInformation(updatedInformation);
                setSaved(true);

                dispatch(fetchInformationAsync(appId));
                setSelectedImage(null)
              }
            }).catch((error) => {
              console.error(error);
              // Handle error if necessary
            });

        } catch (err) {
          console.error(err);
        }
      } else {
        dispatch(updateInformationAsync({ appId, informationId: selectedInformation._id, informationData: selectedInformation }))
          .then((updatedInformation) => {
            if (updatedInformation) {
              // Update the 'notes' state variable with the updated note data
              setSelectedInformation(updatedInformation);
              dispatch(fetchInformationAsync(appId));
              setSaved(true);
              setSelectedImage(null)
            }
          }).catch((error) => {
            console.error(error);
            // Handle error if necessary
          });

      }

      dispatch(fetchInformationAsync(appId));
    }
    dispatch(fetchInformationAsync(appId));
  }

  const handleDeleteInformation = () => {
    if (selectedInformation) {
      dispatch(deleteInformationAsync({ appId, informationId: selectedInformation }));
      console.log(`deleted ${selectedInformation}`);
      dispatch(fetchInformationAsync(appId));
      handleCloseDeleteModal();
    }
    dispatch(fetchInformationAsync(appId));
  };
  if (!informations) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box>
      <Sidebar />
      <Box>
        <Typography variant='h5' fontWeight="700">Informations</Typography>
        <Button align="center" style={{ marginTop: "0.3rem", marginBottom: "1rem" }} variant="contained" color="secondary" onClick={handleOpen}>
          <AddIcon />Add Information
        </Button>
        <Modal open={open} onClose={handleClose}>
          <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, backgroundColor: 'white', borderRadius: 8, padding: 16 }}>
            <Typography variant="h6">Create Information</Typography>
            <TextField label="Title" name="year" fullWidth margin="normal" value={title} onChange={(e) => setTitle(e.target.value)} />
            <ReactQuill
              ref={reactQuillRef}
              id="editor"
              required
              margin="normal"
              fullWidth
              style={{ height: "60%", overflow: 'auto' }}
              modules={{
                formula: true,
                toolbar: [
                  ["video", "bold", "italic", "strike", "underline", "formula", "image", "color", "code-block"],
                  [{ script: "sub" }, { script: "super" }],
                  ["link"],
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  [{ color: [] }, { background: [] }],
                  ['blockquote'],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ['clean']
                ]
              }}
              value={informationBody}
              onChange={handleInformationChange}
              placeholder="Enter Information"
              theme="snow"
            />
            <input type="file" accept="image/*"
              style={{ margin: "0.8rem" }}
              onChange={handleImageChange} />
            {/**is Special? */}
            <Box>
              <label>Is Special:</label>
              <input
                type="checkbox"
                checked={special}
                onChange={(e) => setSpecial(e.target.checked)}
              />
            </Box>
            <Box style={{ display: "flex", gap: "6px", alignItems: "center", justifyContent: "center", paddingBottom: "1rem" }}>
              <Button variant="contained" style={{ background: "#540a61" }} onClick={handleAdd}>
                {loading ? 'Saving...' : saved ? 'Saved Successfully' : 'Save'}
              </Button>
              <Button variant='contained' onClick={handleClose} style={{ background: "red" }}>Cancel</Button>
            </Box>
          </Box>
        </Modal>
      </Box>

      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow style={{ borderBottom: "1px solid #8a898f" }}>
                <TableCell>S/N</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Information</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Special</TableCell>
                <TableCell colSpan="2">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <CircularProgress /> {/* Display loader while fetching data */}
                  </TableCell>
                </TableRow>
              ) : (
                informations.map((information, index) => (
                  <TableRow key={information._id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{information.title}</TableCell>
                    <TableCell>{information.content}</TableCell>
                    <TableCell><img src={information.image} style={{ height: "70px", width: "70px", borderRadius: "50%" }} /></TableCell>
                    <TableCell>{information.is_special ? 'Special' : 'Not Special'}</TableCell>
                    <TableCell>
                      <Button variant='contained' style={{ background: "blue" }} onClick={() => openEditModal(information)}>
                        <EditCalendarIcon background="blue" />
                      </Button>&nbsp;
                      <Button variant='contained' style={{ background: "red" }} onClick={() => handleOpenDeleteModal(information._id)}>
                        <DeleteForeverIcon background="red" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      {/**Edit */}
      {selectedInformation && (
        <Modal open={isEditModalOpen} onClose={closeEditModal}>
          <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, backgroundColor: 'white', borderRadius: 8, padding: 16 }}>
            <Typography variant="h6" style={{ marginBottom: "0.5rem" }}>Edit Information</Typography>
            <TextField
              value={selectedInformation.title}
              label="Title"
              margin="normal"
              fullWidth
              onChange={(e) => setSelectedInformation({ ...selectedInformation, title: e.target.value })}
            />
             <ReactQuill
              ref={reactQuillRef}
              id="editor"
              required
              margin="normal"
              fullWidth
              style={{ height: "60%", overflow: 'auto' }}
              modules={{
                formula: true,
                toolbar: [
                  ["video", "bold", "italic", "strike", "underline", "formula", "image", "color", "code-block"],
                  [{ script: "sub" }, { script: "super" }],
                  ["link"],
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  [{ color: [] }, { background: [] }],
                  ['blockquote'],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ['clean']
                ]
              }}
              value={selectedInformation.content}
              onChange={(content) => setSelectedInformation({ ...selectedInformation, content })}
              placeholder="Enter Information"
              theme="snow"
            />
            <Box>
              <img src={selectedInformation.image} alt="instructor picture" style={{ borderRadius: "50%", width: 100, height: 100 }} />
              <input type="file" accept="image/*" style={{ margin: "0.8rem" }} onChange={handleImageChange} />
            </Box>
            <Box style={{ display: "flex", gap: "6px", alignItems: "center", justifyContent: "center", margin: "1rem" }}>
              <Button variant="contained" style={{ background: "#540a61" }} onClick={handleUpdateInformation}>
                {loading ? 'Saving...' : saved ? 'Saved Successfully' : 'Update'}
              </Button>
              <Button variant="contained" style={{ background: "red", marginLeft: "1rem" }} onClick={closeEditModal}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      )}

      {/**Delete Modal */}
      <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-confirmation-modal"
        aria-describedby="delete-confirmation-modal-description"
      >
        <Box style={{
          position: 'absolute',
          top: '50%', left: '50%',
          transform: 'Translate(-50%, -50%)',
          width: 400,
          backgroundColor: 'white',
          borderRadius: 8, padding: 16
        }}>
          <Box align="center">
            <ReportIcon />
            <Typography fontSize="16px" fontWeight="600" id="delete-confirmation-modal-description">
              Are you sure you want to delete this Information?
            </Typography>
          </Box>
          <Box style={{ display: "flex", gap: "6px", alignItems: "center", justifyContent: "center", margin: "1rem" }}>
            <Button variant='contained' onClick={handleDeleteInformation}>Yes,Delete</Button>
            <Button variant='contained' color='secondary' onClick={handleCloseDeleteModal}>Cancel</Button>
          </Box>
        </Box>
      </Modal>


    </Box>
  )
}

export default Informations