import { createSlice } from '@reduxjs/toolkit';
import { fetchTopicsByCourseId,createTopic,updateTopic,deleteTopicbyId} from '../auth/api';

const topicSlice = createSlice({
  name: 'topic',
  initialState: {
    list: [],
    selectedCourse:null,
    loading: false,
    error: null,
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setTopic: (state, action) => {
      state.loading = false;
      state.error = null;
      state.list = action.payload;
    },
    createTopicSuccess:(state,action)=>{
        state.list.push(action.payload);
        state.loading = false;
        state.error = null;
    },
    updateTopicSuccess: (state, action) => {
      const {topicId, updatedTopic } = action.payload;
      const updatedList = state.list.map((topic) => {
        if (topic._id === topicId) {
          return updatedTopic;
        }
        return topic;
      });
      state.list = updatedList;
      state.loading = false;
      state.error = null;
    },
    deleteTopic: (state, action) => {
      state.list = state.list.filter((topic) => topic._id !== action.payload);
      state.loading = false;
      state.error = null;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setLoading, setTopic,updateTopicSuccess, createTopicSuccess,deleteTopic, setError } = topicSlice.actions;

// Async action to fetch topics by course id
export const fetchTopicsByCourseIdAsync = (appId, courseId) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const topics = await fetchTopicsByCourseId(appId,courseId);
    console.log(topics)
    dispatch(setTopic(topics));
  } catch (error) {
    dispatch(setError(error.message));
  }
};
export const createTopicAsync = ({ appId,categoryId, courseId, topicData }) => async (dispatch) => {
    try {
      dispatch(setLoading());
      const topic = await createTopic(appId,categoryId, courseId, topicData);
      dispatch(createTopicSuccess(topic));
    } catch (error) {
      dispatch(setError(error.message));
    }
  };
  export const updateTopicAsync = ({ appId,categoryId, courseId, topicId,topicData }) => async (dispatch) => {
    try {
      dispatch(setLoading());
      const updatedTopic = await updateTopic(appId,categoryId, courseId, topicId, topicData);
      // Dispatch the updated note to update the state
      dispatch(updateTopicSuccess(updatedTopic));
    } catch (error) {
      dispatch(setError(error.message));
    }
  };
  export const deleteTopicAsync = ({ appId,courseId,topicId }) => async (dispatch) => {
    try {
      dispatch(setLoading());
      const topic = await deleteTopicbyId(appId,courseId,topicId);
      console.log(topic)
      dispatch(deleteTopic(topic));
    } catch (error) {
      dispatch(setError(error.message));
    }
  }; 

export default topicSlice.reducer;
