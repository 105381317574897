import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAppByIdAsync } from '../../redux/reducer/apps/appSlice';
import { useParams } from 'react-router-dom';
import './Header.css'
import { faker } from '@faker-js/faker';
import Sidebar from '../Sidebar';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import AppWebsiteVisits from './app/AppWebsiteVisits';
import AppCurrentVisits from './app/AppCurrentVisits';
import AppWidgetSummary from './app/ApWidgetSummary';
import AppConversionRates from './app/AppConversionRates';
import AppCurrentSubject from './app/AppCurrentSubject';
import AppNewsUpdate from './app/AppNewsUpdate';
import AppOrderTimeline from './app/AppOrderTimeline';
import CircularProgress from '@mui/material/CircularProgress';

function AppDetails() {

  const theme = useTheme();
  const { appId } = useParams();
  const dispatch = useDispatch();


  const app = useSelector((state) => state.apps.selectedApp);
  const loading = useSelector((state) => state.apps.loading);

  useEffect(() => {
    dispatch(fetchAppByIdAsync(appId));
  }, [dispatch, appId]);

  if (!app) {
    return <Box><CircularProgress/>Loading...</Box>;
  }

  return (
    <Box>
      <Sidebar/>
      {loading ?(
                <Box align="center">
                  <CircularProgress /> 
                </Box>  // Display a loader while fetching notes
        ) :(
      <Container maxWidth="xl">
        <Typography style={{fontFamily:"sans-serif"}} variant="h6" fontWeight="700" sx={{ mb: 5 }}>
          Hi, Welcome back to &ensp;({app.title})
        </Typography>

        <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3} >
            <AppWidgetSummary style={{background:"rgb(209, 233, 252)",borderRadius:"12px"}} title="Weekly Activation" total={71400} icon={'ant-design:android-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary style={{background:"rgb(208, 242, 255)",borderRadius:"12px"}} title="New Users" total={13528} color="info" icon={'ant-design:apple-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary style={{background:"rgb(255, 247, 205)",borderRadius:"12px"}} title="App Downloads" total={172331} color="warning" icon={'ant-design:windows-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary style={{background:"rgb(255, 231, 217)",borderRadius:"12px"}} title="Bug Reports" total={34} color="error" icon={'ant-design:bug-filled'} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
              <AppWebsiteVisits
                style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",borderRadius:"12px"}}
                title="Mobile App Downloads"
                subheader="(+43%) than last year"
                chartLabels={[
                  '01/01/2003',
                  '02/01/2003',
                  '03/01/2003',
                  '04/01/2003',
                  '05/01/2003',
                  '06/01/2003',
                  '07/01/2003',
                  '08/01/2003',
                  '09/01/2003',
                  '10/01/2003',
                  '11/01/2003',
                ]}
                chartData={[
                  {
                    name: 'Owerri',
                    type: 'column',
                    fill: 'solid',
                    data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                  },
                  {
                    name: 'Lagos',
                    type: 'area',
                    fill: 'gradient',
                    data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                  },
                  {
                    name: 'Benin',
                    type: 'line',
                    fill: 'solid',
                    data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                  },
                ]}
              />
            </Grid>
            
            <Grid item xs={12} md={6} lg={4}>
              <AppCurrentVisits
                style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",borderRadius:"12px"}}
                title="Current Downloads"
                chartData={[
                  { label: 'Owerri', value: 4344 },
                  { label: 'Lagos', value: 5435 },
                  { label: 'Benin', value: 1443 },
                  { label: 'Enugu', value: 4443 },
                ]}
                chartColors={[
                  theme.palette.primary.main,
                  theme.palette.info.main,
                  theme.palette.warning.main,
                  theme.palette.error.main,
                ]}
              />
            </Grid>
       
          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",borderRadius:"12px"}}
              title="Conversion Rates for Activation"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",borderRadius:"12px"}}
              title="Current Subject"
              chartLabels={['English', 'Statics', 'Physics', 'Computer', 'French', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate 
              style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",borderRadius:"12px"}}
              title="News Update for App"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",borderRadius:"12px"}}
              title="Activation Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, activation, $4220',
                  '12 payments have been made',
                  'Activation #37745 from September',
                  'New activation placed #XF-2356',
                  'New activation placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>
        </Grid>
      </Container>
      )}
    </Box>
  );
}

export default AppDetails;
