import React from 'react';
import './App.css';
import { BrowserRouter as Router,Routes, Route} from 'react-router-dom'
import Login from './components/auth/Login';
import Home from './portal/Home';
import InstructorHome from './portal/InstructorHome';
import Courses from './portal/Courses';
import ProtectedRoute from './ProtectedRoute';
import AppDetails from './portal/components/AppDetails';
import Topics from './portal/Topics';
import Years from './portal/Years';
import Notes from './portal/Notes';
import Question from './portal/Question';
import FlaggedQuestions from './portal/FlaggedQuestions';
import Assessment from './portal/Assessment';
import Activation from './portal/Activation';
import Categories from './portal/Categories';
import Informations from './portal/Informations';
import Box from '@mui/material/Box';
import Users from './portal/Users';
import {createTheme, ThemeProvider } from '@mui/material';
const theme = createTheme({
  typography: {
    fontFamily: "Inter, sans-serif",
  },

});

function App() {

  return (
    <ThemeProvider theme={theme} >
      <Box className="App">
      <Router>
            <Routes>
              <Route path='/' element={<Login />} />
              <Route path='/login' element={<Login />} />
              <Route element={<ProtectedRoute />} >
                <Route path='/admin/apps' element={<Home />} /> 
                <Route path='/instructor/apps' element={<InstructorHome />} /> 
                <Route path='/api/admin/app/:appId' element={<AppDetails />} />
                <Route path='/api/admin/app/:appId/users' element={<Users />} />
                <Route path='/api/admin/app/:appId/topics' element={<Topics />} />
                <Route path='/api/admin/app/:appId/courses' element={<Courses />} />
                <Route path='/api/admin/app/:appId/categories' element={<Categories />} />
                <Route path='/api/admin/app/:appId/assessments' element={<Assessment />} />
                <Route path='/api/admin/app/:appId/years' element={<Years />} />
                <Route path='/api/admin/app/:appId/notes' element={<Notes />} />
                <Route path='/api/admin/app/:appId/questions' element={<Question />} />
                <Route path='/api/admin/app/:appId/flagged-questions' element={<FlaggedQuestions />} />
                <Route path='/api/admin/app/:appId/assessments' element={<Assessment />} />
                <Route path='/api/admin/app/:appId/informations' element={<Informations />} />
                <Route path='/api/admin/app/:appId/activation-codes' element={<Activation />} />
              </Route>    
            </Routes>
        </Router>
     </Box>
    </ThemeProvider>
  );
}

export default App;
