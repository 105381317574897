import { createSlice } from '@reduxjs/toolkit';
import {fetchFlaggedQuestions,deleteFlaggedQuestionById } from '../auth/api';

const flaggedQuestionSlice = createSlice({
  name: 'flaggedquestion',
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setQuestion: (state, action) => {
      state.loading = false
      state.error = null
      state.list = action.payload
    },
    deleteQuestion: (state, action) => {
      state.loading = false
      state.error = null
      state.list = [...state.list.filter((question) => question._id !== action.payload)]
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setLoading,setQuestion,deleteQuestion, setError } = flaggedQuestionSlice.actions;

export const fetchFlaggedsQuestionsAsync = (appId) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const questions = await fetchFlaggedQuestions(appId);
    dispatch(setQuestion(questions));
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const deleteQuestionAsync = ({ appId,questionId }) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const question = await deleteFlaggedQuestionById(appId,questionId);
    console.log(question)
    dispatch(deleteQuestion(questionId));
  } catch (error) {
    dispatch(setError(error.message));
  }
};


export default flaggedQuestionSlice.reducer;
