import { createSlice } from '@reduxjs/toolkit';
import { createQuestionbyOptionId,fetchQuestions,updateQuestion,deleteQuestionbyId } from '../auth/api';

const questionSlice = createSlice({
  name: 'question',
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setQuestion: (state, action) => {
      state.loading = false;
      state.error = null;
      state.list = action.payload;
    },
    updateQuestionSuccess: (state, action) => {
      const { questionId, updatedQuestion } = action.payload;
      const updatedList = state.list.map((question) => {
        if (question._id === questionId) {
          return updatedQuestion;
        }
        return question;
      });
      state.list = updatedList;
      state.loading = false;
      state.error = null;
    },
    createQuestionSuccess:(state,action)=>{
        state.list.push(action.payload);
        state.loading = false;
        state.error = null;
    },
    deleteQuestion: (state, action) => {
      state.list = state.list.filter((question) => question._id !== action.payload);
      state.loading = false;
      state.error = null;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setLoading, setQuestion,updateQuestionSuccess, createQuestionSuccess,deleteQuestion, setError } = questionSlice.actions;

export const fetchQuestionAsync = (appId,categoryId,yearId,courseId,topicId) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const questions = await fetchQuestions(appId,categoryId,yearId,courseId,topicId);
    dispatch(setQuestion(questions));
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const createQuestionAsync = ({ appId,categoryId,yearId, courseId,topicId, questionData }) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const question = await createQuestionbyOptionId(appId,categoryId,yearId, courseId,topicId, questionData);
    console.log(question)
    dispatch(createQuestionSuccess(question));
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const updateQuestionAsync = ({ appId,categoryId,yearId,courseId,topicId, questionId, questionData }) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const updatedNote = await updateQuestion(appId,categoryId,yearId,courseId,topicId, questionId, questionData);
    // Dispatch the updated question to update the state
    dispatch(updateQuestionSuccess(updatedNote));
  } catch (error) {
    dispatch(setError(error.message));
  }
};
export const deleteQuestionAsync = ({ appId,questionId }) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const question = await deleteQuestionbyId(appId,questionId);
    console.log(question)
    dispatch(deleteQuestion(question));
  } catch (error) {
    dispatch(setError(error.message));
  }
};


export default questionSlice.reducer;
