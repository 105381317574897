import React, {useEffect } from 'react';
import PropTypes from 'prop-types';
import TableSortLabel from '@mui/material/TableSortLabel';
import Sidebar from './Sidebar';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import {
  Card,
  Table,
  TableContainer,
  TablePagination,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchUserAsync } from '../redux/reducer/user/userSlice';
const Users = () => {
  const { appId } = useParams();
  const dispatch = useDispatch();

  const [page, setPage] = React.useState(0);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('registeredDate');
  const [selected, setSelected] = React.useState([]);

  useEffect(() => {
    dispatch(fetchUserAsync(appId));
  }, [dispatch, appId]);

  const users = useSelector((state) => state.user.list);
  const loading = useSelector((state) => state.user.loading);

  const rowsPerPage = 10;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = users.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };


  const emptyRows = rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

  const visibleRows = React.useMemo(
    () =>
      stableSort(users, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage,users],
  );


  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
  // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
  // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
  // with exampleArray.slice().sort(exampleComparator)
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }


  if (!users) {
    return <Box>
      <Sidebar />
      <Box>Loading...</Box>
    </Box>;
  }

  const headCells = [
    {
      id: 'serial',
      numeric: false,
      disablePadding: false,
      label: 'S/N',
      canSort: false,
    },
    {
      id: 'username',
      numeric: false,
      disablePadding: false,
      label: 'Username', 
      canSort: true,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email', 
      canSort: false,
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: 'Phone', 
      canSort: false,
    },
    {
      id: 'role',
      numeric: false,
      disablePadding: false,
      label: 'Role', 
      canSort: false,
    },
    {
      id: 'registeredDate',
      numeric: false,
      disablePadding: false,
      label: 'Registered On', 
      canSort: true,
    },
  ];

  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>

          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={headCell.canSort ? createSortHandler(headCell.id) : () =>{}}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };



  return (
    <Box>
      <Sidebar />
      <Card>
        <TableContainer>
          <Table>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={users.length}
            />
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <CircularProgress /> {/* Display loader while fetching data */}
                  </TableCell>
                </TableRow>
              ) : (
                visibleRows.map((user, index) => (
                  <TableRow key={user._id}>
                    <TableCell>{index + 1 + (page * rowsPerPage)}</TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.phone}</TableCell>
                    <TableCell>{user.role}</TableCell>
                    <TableCell>{user.createdAt}</TableCell>
                  </TableRow>
                ))
              )}{emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </Card>
    </Box>
  );
};

export default Users;
