import { createSlice } from '@reduxjs/toolkit';
import { getRegisteredUsers, getUsers } from '../auth/api';
const userSlice = createSlice({
  name: 'user',
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setUser: (state, action) => {
      state.loading = false;
      state.error = null;
      state.list = action.payload;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setLoading, setUser, setError } = userSlice.actions;

export const fetchUserAsync = (appId) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const users = await getRegisteredUsers(appId);
    dispatch(setUser(users));
  } catch (error) {
    dispatch(setError(error.message));
  }
};


export default userSlice.reducer;
