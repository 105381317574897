import { createSlice } from '@reduxjs/toolkit';
import { fetchAssessment,updateAsssessment } from '../auth/api';
const assessmentSlice = createSlice({
  name: 'assessment',
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setAssessment: (state, action) => {
      state.loading = false;
      state.error = null;
      state.list = action.payload;
    },
    updateAssessmentSuccess: (state, action) => {
      const { assessmentId, updatedAssessment } = action.payload;
      const updatedList = state.list.map((assessment) => {
        if (assessment._id === assessmentId) {
          return updatedAssessment;
        }
        return assessment;
      });
      state.list = updatedList;
      state.loading = false;
      state.error = null;
    },
    createAssessmentSuccess:(state,action)=>{
        state.list.push(action.payload);
        state.loading = false;
        state.error = null;
    },
    deleteAssessment: (state, action) => {
      state.list = state.list.filter((assessment) => assessment._id !== action.payload);
      state.loading = false;
      state.error = null;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setLoading, setAssessment,updateAssessmentSuccess, createAssessmentSuccess,deleteAssessment, setError } = assessmentSlice.actions;

export const fetchAssessmentAsync = (appId) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const assessments = await fetchAssessment(appId);
    dispatch(setAssessment(assessments));
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const updateAssessmentAsync = ({ appId, assessmentId, assessmentData }) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const updatedAssessment = await updateAsssessment(appId, assessmentId, assessmentData);
    // Dispatch the updated assessment to update the state
    dispatch(updateAssessmentSuccess(updatedAssessment));
  } catch (error) {
    dispatch(setError(error.message));
  }
};



export default assessmentSlice.reducer;
