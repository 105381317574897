import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchYearAsync } from '../redux/reducer/years/yearSlice';
import { createYearAsync } from '../redux/reducer/years/yearSlice';
import { deleteYearAsync } from '../redux/reducer/years/yearSlice';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { Alert } from "react-bootstrap";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Sidebar from './Sidebar';
import {Modal,Card, Typography, TextField, Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { setError } from '../redux/reducer/years/yearSlice';
import ReportIcon from '@mui/icons-material/Report';

const Years = () => {

    const { appId } = useParams();
    const dispatch = useDispatch();
  
    const years = useSelector((state) => state.year.list);
    const loading = useSelector((state) => state.year.loading);
    const ErrorState = useSelector((state) => state.year.error);

    const [open, setOpen] = useState(false);
    const [year, setYear] = useState('');
    const [selectedYear, setSelectedYear] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [saved, setSaved] = useState(false); // New state for saved
    const [saving, setSaving] = useState(false); // New state for saved
    

    const handleOpen = () => {
      setOpen(true);
      setSaved(false)
    };
    const handleClose = () => {
      setOpen(false);
      setSaved(false)
      setSaving(false)
      setYear('')
    };
    const handleOpenDeleteModal = (year) => {
      setSelectedYear(year);
      setOpenDeleteModal(true);
    };
  
    const handleCloseDeleteModal = () => {
      setOpenDeleteModal(false);
    };


    useEffect(() => {
      dispatch(fetchYearAsync(appId));
    }, [dispatch, appId]);
  
    const handleAdd = () => {
      setSaving(true)
      if(!year){
        dispatch(setError('Input the required field'));
        setSaving(false)
        return;
      }
      try{
        const yearData = {
          year
        };
        dispatch(createYearAsync(appId, yearData))
        .then(()=>{
          setSaving(false)
        
          dispatch(fetchYearAsync(appId));
        })
       
      }
      catch(error){
        setSaving(false)
        setSaved(false)
        dispatch(setError(error))
        console.log(error)
    }
  }

    //Delete a year
    const handleDeleteYear = () => {
      if (selectedYear) {
        dispatch(deleteYearAsync({appId,yearId:selectedYear}));
        console.log(`deleted ${selectedYear}`)
        dispatch(fetchYearAsync( appId));
        handleCloseDeleteModal();
  
      }
      dispatch(fetchYearAsync(appId));
    };

    if (!years) {
      return <Box>Loading...</Box>;
    }
  
  return (
    <Box>
        <Sidebar />
       <Box>
         <Typography variant='h5' fontWeight="700">Years</Typography>
          <Button align="center" style={{marginTop:"0.3rem",marginBottom:"1rem"}} variant="contained" color="secondary" onClick={handleOpen}>
          <AddIcon />Add Year
          </Button>
          <Modal open={open} onClose={handleClose}>
            <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, backgroundColor: 'white', borderRadius: 8, padding: 16 }}>
              <Typography variant="h6">Create Year</Typography>
            <Box style={{padding:"0.2rem"}}>
            {ErrorState && <Alert variant='danger'>{ErrorState}</Alert>}
            </Box>
              <TextField type='number' label="Year" name="year" fullWidth margin="normal" value={year}  onChange={(e) => setYear(e.target.value)} />
             <Box style={{display:"flex",gap:"6px",alignItems:"center",justifyContent:"center",paddingBottom:"1rem"}}>
             <Button variant="contained" style={{background:"#540a61"}} onClick={handleAdd}>
             {saving ? 'Saving...' : saved ? 'Saved Successfully' : 'Save'}
              </Button>
              <Button variant='contained' onClick={handleClose} style={{background:"red"}}>Cancel</Button>
             </Box>
            </Box>
          </Modal>
        </Box>  
         
              <Card>
              <TableContainer>
                  <Table>
                      <TableHead>
                        <TableRow style={{borderBottom:"1px solid #8a898f"}}>
                            <TableCell>S/N</TableCell>
                            <TableCell>Year</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                    <TableBody>
                    {loading ? (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        <CircularProgress /> {/* Display loader while fetching data */}
                      </TableCell>
                    </TableRow>
                    ) : (
                      years.map((year,index) =>(
                      <TableRow key={year._id}>
                          <TableCell>{index+ 1}</TableCell>
                          <TableCell>{year.year}</TableCell>
                          <TableCell> 
                              <Button variant='contained' style={{background:"red"}} onClick={() => handleOpenDeleteModal(year._id)}><DeleteForeverIcon background="red" /> </Button>
                          </TableCell>
                      </TableRow>
                        ))  
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>

              {/**Delete Modal */}
              <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-confirmation-modal"
        aria-describedby="delete-confirmation-modal-description"
      >
        <Box style={{ 
          position: 'absolute', 
          top: '50%', left: '50%',
          transform: 'Translate(-50%, -50%)', 
          width: 400,
          backgroundColor: 'white', 
          borderRadius: 8, padding: 16 }}>
          <Box align="center">
            <ReportIcon/>
            <Typography fontSize="16px" fontWeight="600" id="delete-confirmation-modal-description">
              Are you sure you want to delete this year?
            </Typography>
          </Box>
          <Box style={{display:"flex",gap:"6px",alignItems:"center",justifyContent:"center",margin:"1rem"}}>
          <Button variant='contained' onClick={handleDeleteYear}>Yes,Delete</Button>
          <Button variant='contained' color='secondary' onClick={handleCloseDeleteModal}>Cancel</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
    
};


export default Years;
