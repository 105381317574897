import { createSlice } from '@reduxjs/toolkit';
import { getApps,createApp, getAppById } from '../auth/api';

const appsSlice = createSlice({
  name: 'apps',
  initialState: {
    list: [],
    selectedApp:null,
    loading: false,
    error: null,
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setApps: (state, action) => {
      state.loading = false;
      state.error = null;
      state.list = action.payload;
    },
    createAppSuccess:(state,action)=>{
        state.list.push(action.payload);
    },
    setAppById:(state,action)=>{
        state.loading = false;
        state.error = null;
        state.selectedApp = action.payload;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setLoading, setApps, createAppSuccess,setAppById, setError } = appsSlice.actions;

export const fetchAppsAsync = () => async (dispatch) => {
  try {
    dispatch(setLoading());
    const apps = await getApps();
    dispatch(setApps(apps));
  } catch (error) {
    dispatch(setError(error.message));
  }
};
export const createAppAsync = (appData) => async (dispatch) => {
    try {
      const newApp = await createApp(appData);
      dispatch(createAppSuccess(newApp));
    } catch (error) {
      dispatch(setError(error.message));
    }
  };
export const fetchAppByIdAsync = (appId) => async (dispatch) => {
    try {
      dispatch(setLoading());
      const app = await getAppById(appId);
      dispatch(setAppById(app));
    } catch (error) {
      dispatch(setError(error.message));
    }
  };
  

export default appsSlice.reducer;
