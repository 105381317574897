import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import AppCard from './AppCard';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid } from '@mui/material';
import { fetchAppsAsync } from '../../redux/reducer/apps/appSlice';

function AppsList() {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const apps = useSelector((state) => state.apps.list);
  const loading = useSelector((state) => state.apps.loading);

  useEffect(() => {
    console.log('isAuthenticated:', isAuthenticated);
    const token = localStorage.getItem('token');
    console.log('Token from localStorage:', token);
    if (isAuthenticated) {
      console.log('Fetching apps...');
      dispatch(fetchAppsAsync());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <Grid container spacing={2} margin="0.4rem">
      {loading ? (
        <Container>
          <Row>
            <Col>
              <CircularProgress />
            </Col>
          </Row>
        </Container>
      ) : (
        apps.map((app) => (
          <Grid key={app._id} item xs={12} sm={6} md={2}>
            <AppCard app={app} />
          </Grid>
        ))
      )}
    </Grid>
  );
}

export default AppsList;
