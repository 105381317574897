import { createSlice } from '@reduxjs/toolkit';
import { getCourse,createCourse,updateCourse,getCoursesbyCategoryId, getCourseById ,deleteCoursebyId} from '../auth/api';

const courseSlice = createSlice({
  name: 'course',
  initialState: {
    list: [],
    selectedCourseId:null,
    loading: false,
    error: null,
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setCourse: (state, action) => {
      state.loading = false;
      state.error = null;
      state.list = action.payload;
    },
    createCourseSuccess:(state,action)=>{
        state.list.push(action.payload);
        state.loading = false;
        state.error = null;
    },
    setSelectedCourseId(state, action) {
      state.selectedCourseId = action.payload;
    },
    updateCourseSuccess: (state, action) => {
      const { courseId, updatedCourse } = action.payload;
      const updatedList = state.list.map((course) => {
        if (course._id === courseId) {
          return updatedCourse;
        }
        return course;
      });
      state.list = updatedList;
      state.loading = false;
      state.error = null;
    },
    setCourseById:(state,action)=>{
        state.loading = false;
        state.error = null;
        state.selectedCourseId = action.payload;
    },
    deleteCourse: (state, action) => {
      state.list = state.list.filter((course) => course._id !== action.payload);
      state.loading = false;
      state.error = null;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setLoading, updateCourseSuccess,setCourse,setSelectedCourseId,deleteCourse, createCourseSuccess,selectedCourseId,setCourseById, setError } = courseSlice.actions;

export const fetchCourseAsync = (appId) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const courses = await getCourse(appId);
    console.log(courses)
    dispatch(setCourse(courses));
  } catch (error) {
    dispatch(setError(error.message));
  }
};
export const fetchCourseByCategoryAsync = (appId,categoryId) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const courses = await getCoursesbyCategoryId(appId,categoryId);
    console.log(courses)
    dispatch(setCourse(courses));
  } catch (error) {
    dispatch(setError(error.message));
  }
};
export const createCourseAsync = (appId,categoryId,courseData) => async (dispatch) => {
    try {
      dispatch(setLoading());
      const course = await createCourse(appId,categoryId, courseData);
      dispatch(createCourseSuccess(course));
      console.log(course)
    } catch (error) {
      dispatch(setError(error.message));
    }
  };
export const fetchCourseByIdAsync = (appId, courseId) => async (dispatch) => {
    try {
      dispatch(setLoading());
      const course = await getCourseById(appId, courseId);
      dispatch(setCourseById(course));
    } catch (error) {
      dispatch(setError(error.message));
    }
  };
export const deleteCourseAsync = ({ appId,courseId}) => async (dispatch) => {
    try {
      dispatch(setLoading());
      const course = await deleteCoursebyId(appId,courseId);
      console.log(course)
      dispatch(deleteCourse(course));
    } catch (error) {
      dispatch(setError(error.message));
    }
  }; 
  export const updateCourseAsync = ({ appId, courseId, courseData }) => async (dispatch) => {
    try {
      dispatch(setLoading());
      const updatedCourse = await updateCourse(appId, courseId, courseData);
      // Dispatch the updated course to update the state
      dispatch(updateCourseSuccess(updatedCourse));
    } catch (error) {
      dispatch(setError(error.message));
    }
  };
  
export default courseSlice.reducer;
