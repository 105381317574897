import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducer/auth/authSlice";
import appsReducer from "./reducer/apps/appSlice";
import courseReducer from "./reducer/courses/courseSlice";
import topicReducer from "./reducer/topics/topicSlice";
import userReducer from "./reducer/user/userSlice";
import yearReducer from "./reducer/years/yearSlice";
import noteReducer from "./reducer/notes/notesSlice";
import questionReducer from "./reducer/questions/questionSlice";
import flaggedQuestionReducer from "./reducer/flagged_questions/flaggedQuestionSlice";
import codeReducer from "./reducer/activation/codeSlice";
import informationReducer from "./reducer/information/informationSlice";
import assessmentReducer from "./reducer/assessment/assessmentSlice";
import categoryReducer from "./reducer/category/categorySlice";
export const store = configureStore({
    reducer: {
        auth: authReducer,
        apps:appsReducer,
        course:courseReducer,
        topic:topicReducer,
        year:yearReducer,
        note:noteReducer,
        user:userReducer,
        question:questionReducer,
        flaggedQuestion:flaggedQuestionReducer,
        code:codeReducer,
        information:informationReducer,
        assessment:assessmentReducer,
        category:categoryReducer,
    },
  });

export const dispatch = store.dispatch;
