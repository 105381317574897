import React, { useState, useEffect,useRef  }  from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography,Card,Button, TextField,FormControl,MenuItem,Select } from "@mui/material";
import Sidebar from './Sidebar'

//APIs
import { fetchAssessmentAsync,updateAssessmentAsync } from '../redux/reducer/assessment/assessmentSlice';
import { Modal } from 'react-bootstrap';


const makeStyle=(status)=>{
  if(status === true)
  {
    return {
      background: 'rgb(145 254 159 / 47%)',
      color: 'green',
    }
  }
  else if(status === false)
  {
    return{
      background: '#ffadad8f',
      color: 'red',
    }
  }
  else{
    return{
      background: '#59bfff',
      color: 'white',
    }
  }
}
const Assessment = () => {

  const { appId } = useParams();
  const dispatch = useDispatch();

  //States
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  
  const assessments = useSelector((state) => state.assessment.list);
  const loading = useSelector((state) => state.assessment.loading);
  const [saved, setSaved] = useState(false); // New state for saved

  useEffect(() => {
    dispatch(fetchAssessmentAsync(appId));
  }, [dispatch, appId]);

  if (!assessments) {
    return <Box><CircularProgress/> Loading...</Box>;
  }

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  const openEditModal = (assessment) => {
    setIsEditModalOpen(true);
    setSelectedAssessment(assessment)
  };

  // Function to close the edit modal
  const closeEditModal = () => {
    setSelectedAssessment(null)
    setIsEditModalOpen(false);
  };

  const handleUpdateAssessment = async (event) =>{
    event.preventDefault();

    if(selectedAssessment){
      if(selectedImage){
        const formData = new FormData();
        formData.append('file', selectedImage);
        formData.append('upload_preset', 'istudy');
        try{
          const response = await axios.post(
            'https://api.cloudinary.com/v1_1/istudyapp/image/upload',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
          // Handle the response from Cloudinary after successful upload
          const image = response.data.secure_url;
          selectedAssessment.solution = response.data.secure_url;
          dispatch(updateAssessmentAsync({appId,assessmentId:selectedAssessment._id,assessmentData:selectedAssessment }))
          .then((updatedAssessment) => {
            if (updatedAssessment) {
              // Update the 'notes' state variable with the updated note data
              setSelectedAssessment(updatedAssessment);
              dispatch(fetchAssessmentAsync(appId))
              setSaved(true);
              setSelectedImage(null)
            }
          })
          .catch((error) => {
            console.error(error);
            // Handle error if necessary
          });
          dispatch(fetchAssessmentAsync(appId))
        }catch(err){
          console.log(err)
        }
      }else{
        dispatch(updateAssessmentAsync({appId,assessmentId:selectedAssessment._id,assessmentData:selectedAssessment }))
        .then((updatedAssessment) => {
          if (updatedAssessment) {
            // Update the 'notes' state variable with the updated note data
            setSelectedAssessment(updatedAssessment);
            dispatch(fetchAssessmentAsync(appId))
            setSaved(true);
            setSelectedImage(null)
          }
        })
        .catch((error) => {
          console.error(error);
          // Handle error if necessary
        });
        dispatch(fetchAssessmentAsync(appId))
      }
    }

  }

  return (
    <Box>
        <Sidebar />
        <Box>
          <Typography variant='h6'>Assessments</Typography>

          <Card>        
            <TableContainer>
              <Table >
                  <TableHead>
                    <TableRow style={{borderBottom:"1px solid #8a898f"}}>
                          <TableCell>S/N</TableCell>
                          <TableCell>Question</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Action</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                  {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
                  ): (
                    assessments.map((assessment,index) =>(
                    <TableRow key={assessment._id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{assessment.question}</TableCell>
                        <TableCell>
                          <span style={makeStyle(assessment.status)}>{assessment.status}</span>
                        </TableCell>
                        <TableCell><Button variant='contained' style={{background:"red"}} onClick={() => openEditModal(assessment)}>Details </Button></TableCell>
                    </TableRow>
                      ))  
                    
                  )}</TableBody>
              </Table>
              </TableContainer>
          </Card>
        </Box>

        {/**Edit Modal */}

        {selectedAssessment && (
        <Modal open={isEditModalOpen} onClose={closeEditModal}>
            <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "100%",height:"90%", backgroundColor: 'white', borderRadius: 8, padding: 16 ,overflow: 'auto'}}>  
            <Typography variant="h6" style={{marginBottom:"0.5rem"}}>Edit Assessment</Typography>

            <TextField 
            value={selectedAssessment.question} 
            label="Question"
            style={{marginLeft:"1rem"}}
            onChange={(e) => setSelectedAssessment({ ...selectedAssessment, question: e.target.value })}
            />

          <TextField 
            value={selectedAssessment.answer} 
            label="Answer"
            style={{marginLeft:"1rem"}}
            onChange={(e) => setSelectedAssessment({ ...selectedAssessment, answer: e.target.value })}
            />

            <Typography>Solution Type</Typography>
            <FormControl>
                <Select value={selectedAssessment.solutionType} required
                  Id="question-type-label"
                  onChange={(event) => setSelectedAssessment({...selectedAssessment, solutionType: event.target.value})}
                >
                <MenuItem value="text">Text</MenuItem>
                <MenuItem value="image">Image</MenuItem>
              </Select>
            </FormControl>

            {selectedAssessment.solutionType === 'text' && (
              <TextField 
              label="Solution"
              />
            )}

           {selectedAssessment.solutionType === "image" &&(
              <Box>
                <img src={selectedAssessment.solution} alt="question pic"/>
                <input type="file" accept="image/*" style={{margin:"0.8rem"}} onChange={handleImageChange}  />
              </Box>
            )}
                  
              <Button variant="contained" style={{background:"#540a61"}} onClick={handleUpdateAssessment}>
                 {loading ? 'Saving...' : saved ? 'Saved Successfully' : 'Save'}
              </Button>
              <Button variant="contained" style={{background:"red",marginLeft:"1rem"}} onClick={closeEditModal}>
                  Cancel
              </Button>
            </Box>
        </Modal>
        )}
    </Box>
  )
}

export default Assessment