import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col, Button, Row, Container, Card, Form, Alert } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import { login } from '../../redux/reducer/auth/authSlice';
import { setUserRole } from '../../redux/reducer/auth/authSlice';
import { fetchAppsAsync } from '../../redux/reducer/apps/appSlice';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  async function handleLogin(event) {
    event.preventDefault();

    setIsLoading(true);
    try {
      const response = await fetch('https://api.istudyunleash.com/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      const data = await response.json();

      if (response.ok) {
        setIsLoading(false);
        console.log(data.user.name);
        if (data.user.role === 'admin' || data.user.role === 'instructor') {
          localStorage.setItem('token', data.token);
          localStorage.setItem('user', JSON.stringify(data.user));
          localStorage.setItem('isAuthenticated', true);
          dispatch(login({ isAuthenticated: true, token: data.token, user: JSON.stringify(data.user) }));
          dispatch(setUserRole({ userRole: data.user.role }));
          localStorage.setItem('userRole', data.user.role);

          // Fetch apps immediately after successful login
          await dispatch(fetchAppsAsync());

          await navigate('/admin/apps');
        } else {
          setIsLoading(false);
          setError(`You are unauthorized to access this application ${data.user.name}`);
        }
      } else {
        setIsLoading(false);
        setError(data.message);
      }
    } catch (error) {
      setIsLoading(false);
      setError('An error occurred while trying to login. Please try again later');
      console.error(error);
    }
  }

  return (
    <Box>
      <Container style={{ marginTop: '-6rem', marginBottom: '-5rem' }}>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            <div className="border border-3 "></div>
            <Card className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <h2 className="fw-bold mb-2 text-uppercase">iStudy</h2>
                  <div className="mb-3">
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleLogin}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center">Email address</Form.Label>
                        <Form.Control
                          name="email"
                          type="email"
                          placeholder="Enter email"
                          value={email}
                          onChange={(event) => setEmail(event.target.value.toLowerCase())}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          name="password"
                          type="password"
                          placeholder="Password"
                          value={password}
                          onChange={(event) => setPassword(event.target.value)}
                        />
                      </Form.Group>
                      <div className="d-grid">
                        <Button variant="primary" type="submit">
                          {isLoading ? (
                            <>
                              <CircularProgress size={20} color="inherit" />
                              Verifying...
                            </>
                          ) : (
                            'Login'
                          )}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Box>
  );
};

export default Login;
