import React from 'react';
import './Header.css'
import { Box, Card,  Typography, Stack } from '@mui/material';
import { styled } from '@mui/styles';
import { Link } from 'react-router-dom';
const StyledAppImg = styled('img')({
  top: 0,
  left:0,
  right:0,
  width: '100%',
  height: '100%',
  position: 'absolute',
});

function AppCard({ app }) {
  return (
    <Link to={`/api/admin/app/${app._id}`}>
      <Card 
      style={{marginRight:"1rem",width:"90%",height:"100%",background:"rgb(31, 45, 57)",color:"white",boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",borderRadius:"12px"}}
      >
        <Box sx={{ pt: '100%', position: 'relative' }}>
        <StyledAppImg alt={app.title} src={app.image} />
        </Box>
        <Stack spacing={2} sx={{ p: 3 }}>
          <Link to={`/api/admin/app/${app._id}`} color="white" underline="hover">
            <Typography variant="subtitle2" color="white" >
              {app.title}
            </Typography>
          </Link>
        </Stack>
      </Card>
    </Link>
   
  );
}

export default AppCard;
