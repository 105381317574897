import { createSlice } from '@reduxjs/toolkit';
import { createNotesByTopicId,fetchNotesByTopicId,updateNote,deleteNotebyId } from '../auth/api';

const noteSlice = createSlice({
  name: 'note',
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setNote: (state, action) => {
      state.loading = false;
      state.error = null;
      state.list = action.payload;
    },
    createNoteSuccess:(state,action)=>{
        state.list.push(action.payload);
        state.loading = false;
        state.error = null;
    },
    updateNoteSuccess: (state, action) => {
      const { noteId, updatedNote } = action.payload;
      const updatedList = state.list.map((note) => {
        if (note._id === noteId) {
          return updatedNote;
        }
        return note;
      });
      state.list = updatedList;
      state.loading = false;
      state.error = null;
    },
    deleteNote: (state, action) => {
      state.list = state.list.filter((note) => note._id !== action.payload);
      state.loading = false;
      state.error = null;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setLoading, setNote, createNoteSuccess,updateNoteSuccess,deleteNote, setError } = noteSlice.actions;

export const fetchNotesByTopicIdAsync = (appId,courseId,topicId) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const notes = await fetchNotesByTopicId(appId,courseId,topicId);
    dispatch(setNote(notes));
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const createNoteAsync = ({ appId, courseId,topicId, noteData }) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const note = await createNotesByTopicId(appId, courseId,topicId, noteData);
    dispatch(createNoteSuccess(note));
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const updateNoteAsync = ({ appId, courseId, topicId, noteId, noteData }) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const updatedNote = await updateNote(appId, courseId, topicId, noteId, noteData);
    // Dispatch the updated note to update the state
    dispatch(updateNoteSuccess(updatedNote));
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const deleteNoteAsync = ({ appId,courseId,topicId,noteId }) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const note = await deleteNotebyId(appId,courseId,topicId,noteId);
    console.log(note)
    dispatch(deleteNote(note));
  } catch (error) {
    dispatch(setError(error.message));
  }
}; 



export default noteSlice.reducer;
