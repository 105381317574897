import React, { useState } from 'react';
import { styled } from '@mui/system';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  AppBar,
  Toolbar,
  Badge,
  Avatar,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Home as HomeIcon,
  Notifications as NotificationsIcon,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Groups3Icon from '@mui/icons-material/Groups3';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
const drawerWidth = 240;

const SidebarContainer = styled(motion.nav)(
  ({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
  })
);

const StyledDrawer = styled(Drawer)(
  ({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: drawerWidth,
    },
  })
);

const menuVariants = {
  open: {
    width: drawerWidth,
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  closed: {
    width: 0,
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
};

const Header = styled(AppBar)(
  ({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  })
);

const ToggleButton = styled(IconButton)(
  ({ theme }) => ({
    marginRight: theme.spacing(2),
    visibility: ({ isOpen }) => (isOpen ? 'hidden' : 'visible'),
  })
);

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = ()=>{
      console.log("clear")
      localStorage.removeItem('token');
      localStorage.removeItem('isAuthenticated')
      localStorage.removeItem('user')
  }

  return (
    <>
      <Header style={{background:"#1f2d39"}} position="fixed">
        <Toolbar>
          <ToggleButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleToggleSidebar}
            isOpen={isOpen}
          >
            <MenuIcon />
          </ToggleButton>
          <div style={{ flexGrow: 1 }} />
         
        </Toolbar>
      </Header>
      <Toolbar /> {/* Add a Toolbar to maintain content spacing */}
      <SidebarContainer
        style={{background:"#ecedf2"}}
        component="nav"
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        variants={menuVariants}
      >
        <StyledDrawer  variant="persistent" anchor="left" open={isOpen}>
          <List style={{paddingTop:"6rem"}}>
            <ListItem button component={Link} to="/admin/apps">
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Apps" />
            </ListItem>
            <ListItem button component={Link} onClick={handleLogout} to="/login">
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="logout" />
            </ListItem>
          </List>
        </StyledDrawer>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
