import { createSlice } from '@reduxjs/toolkit';
import {getCategory, getCategoryById,createCategory,UpdateCategory,deleteCategorybyId } from '../auth/api';
const categorySlice = createSlice({
  name: 'category',
  initialState: {
    list: [],
    selectedcategoryId:null,
    loading: false,
    error: null,
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setCategory: (state, action) => {
      state.loading = false;
      state.error = null;
      state.list = action.payload;
    },
    createCategorySuccess:(state,action)=>{
        state.list.push(action.payload);
        state.loading = false;
        state.error = null;
    },
    setSelectedCategoryId(state, action) {
      state.selectedcategoryId = action.payload;
    },
    setCategoryById:(state,action)=>{
        state.loading = false;
        state.error = null;
        state.selectedcategoryId = action.payload;
    },
    updateCategorySuccess: (state, action) => {
        const { categoryId, updatedCategory } = action.payload;
        const updatedList = state.list.map((category) => {
          if (category._id === categoryId) {
            return updatedCategory;
          }
          return category;
        });
        state.list = updatedList;
        state.loading = false;
        state.error = null;
      },
    deleteCategory: (state, action) => {
      state.list = state.list.filter((category) => category._id !== action.payload);
      state.loading = false;
      state.error = null;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setLoading, setCategory,updateCategorySuccess,setSelectedCategoryId,deleteCategory, createCategorySuccess,selectedCategoryId,setCategoryById, setError } = categorySlice.actions;

export const fetchCategoryAsync = (appId) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const categories = await getCategory(appId);
    console.log(categories)
    dispatch(setCategory(categories));
  } catch (error) {
    dispatch(setError(error.message));
  }
};
export const createCategoryAsync = (appId,categoryData) => async (dispatch) => {
    try {
      dispatch(setLoading());
      const category = await createCategory(appId, categoryData);
      dispatch(createCategorySuccess(category));
      console.log(category)
    } catch (error) {
      dispatch(setError(error.message));
    }
  };
export const fetchCategoryByIdAsync = (appId, categoryId) => async (dispatch) => {
    try {
      dispatch(setLoading());
      const category = await getCategoryById(appId, categoryId);
      dispatch(setCategoryById(category));
    } catch (error) {
      dispatch(setError(error.message));
    }
  };
export const deleteCategoryAsync = ({ appId,categoryId}) => async (dispatch) => {
    try {
      dispatch(setLoading());
      const category = await deleteCategorybyId(appId,categoryId);
      console.log(category)
      dispatch(deleteCategory(category));
    } catch (error) {
      dispatch(setError(error.message));
    }
  }; 
  export const updateCategoryAsync = ({ appId, categoryId, categoryData }) => async (dispatch) => {
    try {
      dispatch(setLoading());
      const updatedCategory = await UpdateCategory(appId, categoryId, categoryData);
      // Dispatch the updated assessment to update the state
      dispatch(updateCategorySuccess(updatedCategory));
    } catch (error) {
      dispatch(setError(error.message));
    }
  };
  
export default categorySlice.reducer;
