import { Outlet } from 'react-router-dom'
import Login from './components/auth/Login';
import { Box } from '@mui/material';
const ProtectedRoute = () => {

  const isAuthenticated = localStorage.getItem('isAuthenticated')
  // show unauthorized screen if no user is found in redux store
  if (!isAuthenticated) {
    return (
      <Box className='unauthorized'>
          <Login />
      </Box>
    )
  }

  // returns child route elements
  return <Outlet />
}
export default ProtectedRoute

