import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchAppsAsync } from '../redux/reducer/apps/appSlice';
import AppsList from './components/AppList';
import AppModal from './components/AppModal';
import Sidebar from './components/Sidebar';
import { Box } from '@mui/material';

const Home = () => {
  const dispatch = useDispatch();
  const userRole = localStorage.getItem('userRole')
  
  React.useEffect(() => {
    dispatch(fetchAppsAsync());
  }, [dispatch]);

  function handleFetch(){
      dispatch(fetchAppsAsync())
  }

  handleFetch()

  return (
    <Box>
      <Sidebar />
      {userRole === 'admin' && <AppModal />}
      <Box>
        <AppsList />
      </Box>
    </Box>
  );
};

export default Home;
