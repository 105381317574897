import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchAppByIdAsync } from '../redux/reducer/apps/appSlice';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  AppBar,
  Toolbar,
  Box,
} from '@mui/material';

import {
  Menu as MenuIcon,
  Home as HomeIcon,
  AccountBalance as AccountBalanceIcon,
  Topic as TopicIcon,
  Autorenew as AutorenewIcon,
  Grading as GradingIcon,
  Hearing as HearingIcon,
  Assessment as AssessmentIcon,
  HdrAuto as HdrAutoIcon,
  HelpCenter as HelpCenterIcon,
  AccountCircle as AccountCircleIcon,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import VpnKeyOffIcon from '@mui/icons-material/VpnKeyOff';
import { logout } from '../redux/reducer/auth/authSlice';

const drawerWidth = 240;

const SidebarContainer = styled(motion.nav)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
  },
}));

const menuVariants = {
  open: {
    width: drawerWidth,
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  closed: {
    width: 0,
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
};

const Header = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  flexDirection: 'row',
  alignItems: 'right',
  justifyContent: 'space-between',
}));

const ToggleButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  visibility: ({ isOpen }) => (isOpen ? 'hidden' : 'visible'),
}));

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const { appId } = useParams();
  const dispatch = useDispatch();
  const app = useSelector((state) => state.apps.selectedApp);
  const userRole = localStorage.getItem('userRole')
  useEffect(() => {
    dispatch(fetchAppByIdAsync(appId));
  }, [dispatch, appId]);

  if (!app) {
    return <Box>Loading...</Box>;
  }

  const handleLogout = () => {
    console.log('clear');
    localStorage.removeItem('token');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('user');
    dispatch(logout())
  };

  return (
    <>
      <Header style={{ background: 'black', alignContent: 'right' }} position="fixed">
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <ToggleButton color="inherit" aria-label="open drawer" edge="start" onClick={handleToggleSidebar} isOpen={isOpen}>
            <MenuIcon />
          </ToggleButton>
          <Box style={{ flexGrow: 1 }} />
        </Toolbar>
      </Header>
      <Toolbar /> {/* Add a Toolbar to maintain content spacing */}
      <SidebarContainer
        style={{ background: '#e2e6f4' }}
        component="nav"
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        variants={menuVariants}
      >
        <StyledDrawer variant="persistent" anchor="left" open={isOpen}>
          <List style={{ paddingTop: '6rem' }}>
            <ListItem button component={Link} to="/admin/apps">
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Back to Apps" />
            </ListItem>
            <ListItem button component={Link} to={`/api/admin/app/${app._id}/categories`}>
              <ListItemIcon>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText primary="Course Category" />
            </ListItem>
            <ListItem button component={Link} to={`/api/admin/app/${app._id}/courses`}>
              <ListItemIcon>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText primary="Courses" />
            </ListItem>
            <ListItem button component={Link} to={`/api/admin/app/${app._id}/topics`}>
              <ListItemIcon>
                <TopicIcon />
              </ListItemIcon>
              <ListItemText primary="Topics" />
            </ListItem>
            <ListItem button component={Link} to={`/api/admin/app/${app._id}/years`}>
              <ListItemIcon>
                <AutorenewIcon />
              </ListItemIcon>
              <ListItemText primary="Years" />
            </ListItem>
            <ListItem button component={Link} to={`/api/admin/app/${app._id}/notes`}>
              <ListItemIcon>
                <GradingIcon />
              </ListItemIcon>
              <ListItemText primary="Notes" />
            </ListItem>
            <ListItem button component={Link} to={`/api/admin/app/${app._id}/questions`}>
              <ListItemIcon>
                <HearingIcon />
              </ListItemIcon>
              <ListItemText primary="Questions" />
            </ListItem>

            <ListItem button component={Link} to={`/api/admin/app/${app._id}`}>
              <ListItemIcon>
                <HdrAutoIcon />
              </ListItemIcon>
              <ListItemText primary="App-Details" />
            </ListItem>
            {userRole === 'admin' && (
              <>
                <ListItem button component={Link} to={`/api/admin/app/${app._id}/assessments`}>
                  <ListItemIcon>
                    <AssessmentIcon />
                  </ListItemIcon>
                  <ListItemText primary="Assessment" />

                </ListItem>
                <ListItem button component={Link} to={`/api/admin/app/${app._id}/flagged-questions`}>
                  <ListItemIcon>
                    <HearingIcon />
                  </ListItemIcon>
                  <ListItemText primary="Flagged Questions" />
                </ListItem>

                <ListItem button component={Link} to={`/api/admin/app/${app._id}/informations`}>
                  <ListItemIcon>
                    <HelpCenterIcon />
                  </ListItemIcon>
                  <ListItemText primary="Informations" />
                </ListItem>
                <ListItem button component={Link} to={`/api/admin/app/${app._id}/activation-codes`}>
                  <ListItemIcon>
                    <VpnKeyOffIcon />
                  </ListItemIcon>
                  <ListItemText primary="Activation Codes" />
                </ListItem>
                <ListItem button component={Link} to={`/api/admin/app/${app._id}/users`}>
                  <ListItemIcon>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Users" />
                </ListItem>
              </>
            )}
            <ListItem button component={Link} onClick={handleLogout} to="/login">
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </StyledDrawer>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
