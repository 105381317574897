import React, { useState, useEffect,useRef  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {Modal,Card, AccordionSummary, AccordionDetails, Typography, TextField, Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Sidebar from './Sidebar';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import Box from '@mui/material/Box';
import ReactPlayer from 'react-player';
import CircularProgress from '@mui/material/CircularProgress';
import ReactAudioPlayer from 'react-audio-player';
import ReportIcon from '@mui/icons-material/Report';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Latex from 'react-latex-next';
import EditIcon from '@mui/icons-material/Edit';

// KaTeX dependency
import "katex/dist/katex.css";
// Quill dependency
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
// MathQuill dependency
import "./jquery";
import "@edtr-io/mathquill/build/mathquill.js";
import "@edtr-io/mathquill/build/mathquill.css";
// mathquill4quill include
import mathquill4quill from "mathquill4quill";
import "mathquill4quill/mathquill4quill.css";
import parse from 'html-react-parser';
import './default.css'
//APIs
import { fetchCourseAsync} from '../redux/reducer/courses/courseSlice';
import { fetchTopicsByCourseIdAsync} from '../redux/reducer/topics/topicSlice';
import { createNoteAsync, updateNoteAsync } from '../redux/reducer/notes/notesSlice';
import { fetchNotesByTopicIdAsync } from '../redux/reducer/notes/notesSlice';
import { deleteNoteAsync } from '../redux/reducer/notes/notesSlice';
import katex from "katex";
window.katex = katex;

const Notes = () => {

  const { appId} = useParams();
  const dispatch = useDispatch();

  const courses = useSelector((state) => state.course.list);
  const topics = useSelector((state) => state.topic.list);
  const notes = useSelector((state) => state.note.list);
  const loading = useSelector((state) => state.topic.loading);
  const isLoading = useSelector((state) => state.note.loading);

    
  const [open, setOpen] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isAudioModalOpen, setIsAudioModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [SelectedNote, setSelectedNote] = useState(null);
  const [showEditor, setShowEditor] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [saved, setSaved] = useState(false); // New state for saved

  const [title, setTitle] = useState('');
  const [audioLink, setAudioLink] = useState('audio link');
  const [videoLink, setVideoLink] = useState('video link');
  const [content, setContent] = useState("");
  const reactQuillRef = useRef(null);

  useEffect(() => {
    if(reactQuillRef.current){
      const enableMathQuillFormulaAuthoring = mathquill4quill({ Quill, katex });
    enableMathQuillFormulaAuthoring(reactQuillRef.current.editor, {
      displayHistory: true,
      operators: [
        ["\\pm", "\\pm"],
        ["\\sqrt{x}", "\\sqrt"],
        ["\\sqrt[3]{x}", "\\sqrt[3]{}"],
        ["\\sqrt[n]{x}", "\\nthroot"],
        ["\\frac{x}{y}", "\\frac"],
        ["\\sum^{s}_{x}{d}", "\\sum"],
        ["\\prod^{s}_{x}{d}", "\\prod"],
        ["\\coprod^{s}_{x}{d}", "\\coprod"],
        ["\\int^{s}_{x}{d}", "\\int"],
        ["\\binom{n}{k}", "\\binom"],
        ['E = mc^2', 'E = mc^2'],
        ['\\Delta x = \\frac{v_f - v_i}{t}', '\\Delta x = \\frac{v_f - v_i}{t}'],
        ['x = \\frac{-b \\pm \\sqrt{b^2 - 4ac}}{2a}', 'x = \\frac{-b \\pm \\sqrt{b^2 - 4ac}}{2a}'],
        ['\\vec{F} = m\\vec{a}', '\\vec{F} = m\\vec{a}'],
        ['\\sin^2{x} + \\cos^2{x} = 1', '\\sin^2{x} + \\cos^2{x} = 1'],
        ['\\frac{d}{dx}e^x = e^x', '\\frac{d}{dx}e^x = e^x'],
        ['\\int_{a}^{b} f(x) dx', '\\int_{a}^{b} f(x) dx'],
        ['\\ln{x}', '\\ln'],
        ["\\alpha", "\\alpha"],
        ["\\beta", "\\beta"],
        ["\\gamma", "\\gamma"],
        ["\\delta", "\\delta"],
        ["\\epsilon", "\\epsilon"],
        ['\\sin{x}', '\\sin'],
        ['\\cos{x}', '\\cos'],
        ['\\tan{x}', '\\tan'],
        ['\\cot{x}', '\\cot'],
        ['\\sec{x}', '\\sec'],
        ['\\csc{x}', '\\csc'],
        ['\\arcsin{x}', '\\arcsin'],
        ['\\arccos{x}', '\\arccos'],
        ['\\arctan{x}', '\\arctan'],
        ['\\sinh{x}', '\\sinh'],
        ['\\cosh{x}', '\\cosh'],
        ['\\tanh{x}', '\\tanh'],
        ['\\coth{x}', '\\coth'],
        ['\\sech{x}', '\\sech'],
        ['\\csch{x}', '\\csch'],
        ['\\arcsinh{x}', '\\arcsinh'],
        ['\\arccosh{x}', '\\arccosh'],
        ['\\arctanh{x}', '\\arctanh'],
        ['\\arccoth{x}', '\\arccoth'],
        ['\\arcsech{x}', '\\arcsech'],
        ['\\arccsch{x}', '\\arccsch']
      ],
      displayDeleteButtonOnHistory: true
    });
    }
  }, [reactQuillRef.current]);

  useEffect(() => {
    dispatch(fetchCourseAsync(appId));
  }, [dispatch, appId]);



  useEffect(() => {
    if (selectedCourse) {
      // Fetch the list of topics when the selected course changes
      dispatch(fetchTopicsByCourseIdAsync(appId,selectedCourse));
    }else{
      setSelectedCourse('')
    }
  }, [selectedCourse]);

  if (!courses) {
    return <Box>Loading...</Box>;
  }

   //selecting a course
   const handleCourseSelection = (courseId) => {
    setSelectedCourse(courseId._id);
   // dispatch(fetchTopicsByCourseIdAsync(appId, courseId._id));
  };
  
  const getSelectedCourseName = () => {
    const course = courses.find((course) => course._id === selectedCourse);
    return course ? course.name.substring(0,20) + '...' : 'Select a Course';
  };
    
  //logic for opening the editor fetching the notes 
  const handleViewNotes = (topicId) => {
    setSelectedTopic(topicId);
    console.log(topicId)
    dispatch(fetchNotesByTopicIdAsync(appId,selectedCourse,topicId))
    setShowEditor(true);
    console.log("notes page opened")
  };
  const getSelectedTopicName = () => {
    const topic = topics.find((topic) => topic._id === selectedTopic);
    return topic ? topic.title  : 'Select a Topic';
  };

  const handleEditorChange = (value) => {
    setContent(value);
  };
  const handleCloseEditor = () => {
    setShowEditor(false);
  };

  const handleOpen = (topicId) => {
    setOpen(true);
    setSelectedTopic(topicId);
    setSaved(false)
  };
  const handleClose = () => {
    setOpen(false);
    setSaved(false)
    setTitle('')
    setContent('')
  };
  const handleOpenDeleteModal = (note) => {
    setSelectedNote(note);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const openEditModal = (note) => {
    setSelectedNote(note);
    setIsEditModalOpen(true);
  };

  // Function to close the edit modal
  const closeEditModal = () => {
    setSelectedNote(null);
    setIsEditModalOpen(false);
  };

  //Video Modal
  const openVideoModal = () => {
    setIsVideoModalOpen(true);
  };
  const closeVideoModal = () => {
    setIsVideoModalOpen(false);
  };

  //Audio Modal
  const openAudioModal = () => {
    setIsAudioModalOpen(true);
  };
  const closeAudioModal = () => {
    setIsAudioModalOpen(false);
  };

  // Logic for creating a new note
  const handleCreateNote = (e) => {
    e.preventDefault()

    if(!(title && content && audioLink && videoLink)){
      alert('Please input the required fields')
      return
    }

    const Box = document.createElement("Box");
    Box.innerHTML = content;

    // Handle rich text elements
    const richTextElements = Box.getElementsByClassName("ql-editor");
    for (let i = 0; i < richTextElements.length; i++) {
      const richTextContent = richTextElements[i].innerHTML;
      richTextElements[i].innerHTML = richTextContent;
    }
    const formulaElements = Box.getElementsByClassName("ql-formula");
    for (let i = 0; i < formulaElements.length; i++) {
      const dataValue = formulaElements[i].getAttribute("data-value");
      const latex = dataValue ? `$$${dataValue}$$` : "";
      formulaElements[i].textContent = latex;
    }

    const plainText = Box.textContent;
    console.log("Saving content:", plainText);

    const noteData = {
      title,
      content,
      audioLink,
      videoLink
    };
    dispatch(createNoteAsync({appId, courseId:selectedCourse,topicId:selectedTopic, noteData }))
    .then(()=>{
      setSaved(true)
    })
    console.log(noteData)
  };

  const handleNoteUpdate = () => {
    if (SelectedNote && SelectedNote._id) {
      dispatch(updateNoteAsync({ appId, courseId: selectedCourse, topicId: selectedTopic, noteId: SelectedNote._id, noteData: SelectedNote }))
        .then((updatedNote) => {
          if (updatedNote) {
            // Update the 'notes' state variable with the updated note data
            setSelectedNote(updatedNote);
            dispatch(fetchNotesByTopicIdAsync(appId,selectedCourse,selectedTopic))
            setSaved(true);
          
          }
        })
        .catch((error) => {
          console.error(error);
          // Handle error if necessary
        });
    }
    handleViewNotes(selectedTopic)
    dispatch(fetchNotesByTopicIdAsync(appId,selectedCourse,selectedTopic))
  };

  const handleDeleteNote = () => {
    if (SelectedNote) {
      dispatch(deleteNoteAsync({appId,courseId:selectedCourse,topicId:selectedTopic,noteId:SelectedNote}));
      dispatch(fetchNotesByTopicIdAsync(appId,selectedCourse,selectedTopic))
      handleCloseDeleteModal()
    }
    dispatch(fetchNotesByTopicIdAsync(appId,selectedCourse,selectedTopic))
  };


  return (
    <Box>
        <Sidebar/> 
         <Box>   
         {showEditor ? (
          <Box>
            <Button variant='contained' color='secondary' style={{display:"flex",marginLeft:"1rem"}} onClick={handleCloseEditor}>Close Editor</Button>
            
            {isLoading ? (
                  <CircularProgress /> // Display a loader while fetching notes
                ) :(
            notes.length > 0 ? (
                notes.map((note) => (
                  <Card key={note._id} style={{marginTop:"1rem"}}>
                     <Box style={{display:"flex",flexDirection:"column",gap:"6px",padding:"1rem",alignItems:"center",justifyContent:"center",}}>
                      <Typography variant='h6' fontSize="13px" fontWeight="600">Topic:{getSelectedTopicName()} 
                      <Button onClick={() => handleOpenDeleteModal(note._id)}><DeleteForeverIcon background="red" /> </Button>
                      <Button 
                                 
                                 onClick={() => openEditModal(note)}>
                                  <EditIcon />
                                </Button>
                      </Typography>
                      <Typography variant='h6'fontSize="13px" fontWeight="600">Note-Title:{note.title}</Typography>
                    </Box>
                    <Box style={{textAlign:"left",margin:"0.7rem"}}>
                    <Typography marginBottom="0.8rem" display="flex">
                        <Latex >{note.content}</Latex>
                    </Typography>
                    </Box>
                    <Box style={{display:"flex",gap:"6px",alignItems:"center",justifyContent:"center",margin:"0.4rem"}}>
                      <Button variant='contained' style={{background:"#150f70"}} onClick={openAudioModal}>play <AudiotrackIcon /> </Button>
                      <Modal open={isAudioModalOpen} onClose={closeAudioModal}>
                          <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'Translate(-50%, -50%)', width: "400", borderRadius: 8 }}>    
                                <ReactAudioPlayer
                                  src={note.audioLink}
                                  autoPlay
                                  controls
                                />
                                <Button variant="contained" style={{background:"red"}} onClick={closeAudioModal}>
                                  <ClearIcon />
 
                                </Button>
                               
                          </Box>
                      </Modal>
                      <Button variant='contained' style={{background:"#bd1212"}} onClick={openVideoModal}>play <VideoCameraBackIcon /> </Button>
                      <Modal open={isVideoModalOpen} onClose={closeVideoModal}>
                     <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'Translate(-50%, -50%)', width: "90%",height:"70%", borderRadius: 8 }}>
                          <Button variant="contained" style={{background:"red"}} onClick={closeVideoModal}>
                            <ClearIcon />
                          </Button>
                          <ReactPlayer
                            url={note.videoLink}
                            controls
                            style={{position:"absolute"}}
                            width="100%"
                            height="100%"
                          />
                      </Box>
                    </Modal>
                    </Box>
                  </Card>
                ))
              ) : (
                  <Card style={{marginTop:"1rem"}}>
                      <AccordionSummary>
                        <Typography variant='h6'fontSize="18px"  fontWeight="600">Topic: {getSelectedTopicName()}</Typography>
                      </AccordionSummary>
                     <AccordionDetails>
                        <Typography align="center">
                            No notes available for this topic.
                        </Typography>
                     </AccordionDetails>
                  </Card>
              )
                )}

   
          <Box>
      <Modal open={isEditModalOpen} onClose={closeEditModal}>
        <Box  style={{ position: 'absolute', top: '50%', left: '50%', transform: 'Translate(-50%, -50%)', width: "100%",height:"80%", backgroundColor: 'white', borderRadius: 8, padding: 16,overflow:'auto' }}>
        {SelectedNote && (
            <Box>
              <Typography variant='h6'>Edit Note</Typography>
              <TextField
                label="Title"
                margin="normal"
                style={{marginTop:"1rem",marginBottom:"0.5rem"}}
                value={SelectedNote.title}
                onChange={(e) => setSelectedNote({ ...SelectedNote, title: e.target.value })}
              />
              <ReactQuill
                    ref={reactQuillRef}
                    id="editor"
                    required
                    margin="normal"
                    fullWidth 
                    style={{height:"40%", overflow: 'auto'}}
                    modules={{
                      formula: true,
                      toolbar: [
                        ["video","bold", "italic", "strike", "underline", "formula","image","color","code-block"],
                        [{ script: "sub" }, { script: "super" }],
                        ["link"],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ color: [] }, { background: [] }],
                        ['blockquote'],
                        [{ list: "ordered" }, { list: "bullet" }],
                        ['clean']
                      ]
                    }}
                value={SelectedNote.content}
                
                 onChange={(content) => setSelectedNote({ ...SelectedNote, content })}
              />
                
              <TextField required label="Audio Link" name="audioLink" fullWidth margin="normal"value={SelectedNote.audioLink}onChange={(e) => setSelectedNote({ ...SelectedNote, audioLink: e.target.value })} />
              <TextField required label="Video Link" type="url" name="videoLink" fullWidth margin="normal"value={SelectedNote.videoLink} onChange={(e) => setSelectedNote({ ...SelectedNote, videoLink: e.target.value })} />
              {/* Add other input fields for other note details */}
              <Button variant="contained" color="primary" onClick={handleNoteUpdate}>
              {isLoading ? 'Saving...' : saved ? 'Saved Successfully' : 'Save'}
              </Button>
              <Button variant="contained" color="secondary" onClick={closeEditModal}>
                Cancel
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
            </Box>    
          </Box>
          ) :  (
          <Box>
            <Modal open={open} onClose={handleClose}>
            <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'Translate(-50%, -50%)', width: "100%",height:"80%", backgroundColor: 'white', borderRadius: 8, padding: 16 }}>
              <Typography variant="h6" fontSize="13px">Add Note</Typography>
              <TextField required label="Title" name="title" fullWidth margin="normal" value={title}  onChange={(e) => setTitle(e.target.value)} />
             
                <ReactQuill
                    ref={reactQuillRef}
                    id="editor"
                    required
                    margin="normal"
                    fullWidth 
                    style={{height:"40%", overflow: 'auto'}}
                    modules={{
                      formula: true,
                      toolbar: [
                        ["video","bold", "italic", "strike", "underline", "formula","image","color","code-block"],
                        [{ script: "sub" }, { script: "super" }],
                        ["link"],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ color: [] }, { background: [] }],
                        ['blockquote'],
                        [{ list: "ordered" }, { list: "bullet" }],
                        ['clean']
                      ]
                    }}
                    value={content}
                    onChange={handleEditorChange}
                    placeholder="Type text here"
                  />
               
              <TextField required label="Audio Link" name="audioLink" fullWidth margin="normal" value={audioLink}  onChange={(e) => setAudioLink(e.target.value)} />
              <TextField required label="Video Link" type="url" name="videoLink" fullWidth margin="normal" value={videoLink}  onChange={(e) => setVideoLink(e.target.value)} />
              <Button variant="contained" style={{background:"#540a61"}} color="primary" onClick={handleCreateNote}>
              {isLoading ? 'Saving...' : saved ? 'Saved Successfully' : 'Save'}
              </Button>
              <Button variant="contained" style={{marginLeft:"1rem"}} color="primary" onClick={handleClose}>
                Cancel
              </Button>
            </Box>
          </Modal>
            <Box>
            <Typography variant='h5' fontWeight="700">Lecture Notes</Typography>
            <Dropdown>
            <Dropdown.Toggle align="center"  id="dropdown-basic" style={{marginTop:"0.3rem",marginBottom:"1rem",fontWeight:"600",border:"none",background:"#9c27b0"   }}>
              {getSelectedCourseName()}
              </Dropdown.Toggle>
              <Dropdown.Menu style={{fontSize:"15px" }}>
              {courses.map((courseId) => (
                          <Dropdown.Item
                          key={courseId._id}
                          onClick={() => handleCourseSelection(courseId)}
                          href="#">{courseId.name}</Dropdown.Item>  
                  ))}
                  </Dropdown.Menu>
          </Dropdown>
         </Box>
        <Card>
          <TableContainer>
            <Table>
              <TableHead>
                      <TableRow style={{borderBottom:"1px solid #8a898f"}}>
                        <TableCell>S/N</TableCell>
                        <TableCell>Topic Title</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
            </TableHead>
            <TableBody>
            {!selectedCourse ? (
            <TableRow>
              <TableCell align="center" colSpan={5}>
                  <Typography>Select a course to see topics</Typography>
              </TableCell>
            </TableRow>
          ) : loading ? (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <CircularProgress /> {/* Render a loader while fetching */}
                  </TableCell>
                </TableRow>
              ) :(
              topics.map((topicId,index) => (
              <TableRow key={topicId._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{topicId.title}</TableCell>
                  <TableCell>
                    <Button variant="contained" 
                    onClick={()=> handleViewNotes(topicId._id)} 
                    color="primary"><FolderOpenIcon/>View Notes</Button>
                  </TableCell>
                  <TableCell>
                    <Button variant="contained" 
                    onClick={()=>handleOpen(topicId._id)}
                    color="secondary"><AddIcon />Note</Button>
                  </TableCell>
              </TableRow> 
            ))
          )}
            {!loading && topics.length === 0 && selectedCourse && ( // Render "No topics" message if there are no topics and loading has finished
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <Typography>No topic for this course.</Typography>
                </TableCell>
              </TableRow>
            )}
        </TableBody>
  
        </Table>  
      </TableContainer>
     </Card>
    </Box>
      )}
    </Box>  

       {/**Delete Modal */}
    <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-confirmation-modal"
        aria-describedby="delete-confirmation-modal-description"
      >
        <Box style={{ 
          position: 'absolute', 
          top: '50%', left: '50%',
          transform: 'Translate(-50%, -50%)', 
          width: 400,
          backgroundColor: 'white', 
          borderRadius: 8, padding: 16 }}>
          <Box align="center">
            <ReportIcon/>
            <Typography fontSize="16px" fontWeight="600" id="delete-confirmation-modal-description">
              Are you sure you want to delete this note?
            </Typography>
          </Box>
          <Box style={{display:"flex",gap:"6px",alignItems:"center",justifyContent:"center",margin:"1rem"}}>
          <Button variant='contained' onClick={handleDeleteNote}>Yes,Delete</Button>
          <Button variant='contained' color='secondary' onClick={handleCloseDeleteModal}>Cancel</Button>
          </Box>
        </Box>
      </Modal>    
  </Box>
  )
}

export default Notes
