import { createSlice } from '@reduxjs/toolkit';
import { getYears,createYear,deleteYearbyId } from '../auth/api';

const yearSlice = createSlice({
  name: 'year',
  initialState: {
    list: [],
    loading: false,
    error: '',
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setYear: (state, action) => {
      state.loading = false;
      state.error = null;
      state.list = action.payload;
    },
    deleteYear: (state, action) => {
      state.list = state.list.filter((year) => year._id !== action.payload);
      state.loading = false;
      state.error = null;
    },
    createYearSuccess:(state,action)=>{
        state.list.push(action.payload);
        state.loading = false;
        state.error = null;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setLoading, setYear, deleteYear,createYearSuccess,setError } = yearSlice.actions;

export const fetchYearAsync = (appId) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const years = await getYears(appId);
    dispatch(setYear(years));
  } catch (error) {
    dispatch(setError(error.message));
  }
};
export const createYearAsync = (appId,yearData) => async (dispatch) => {
    try {
      dispatch(setLoading());
      const year = await createYear(appId, yearData);
      dispatch(createYearSuccess(year));
    } catch (error) {
      dispatch(setError(error.message));
    }
  };
  export const deleteYearAsync = ({ appId,yearId}) => async (dispatch) => {
    try {
      dispatch(setLoading());
      const year = await deleteYearbyId(appId,yearId);
      console.log(year)
      dispatch(deleteYear(year));
    } catch (error) {
      dispatch(setError(error.message));
    }
  }; 
export default yearSlice.reducer;
