import React, { useState, useEffect,useRef  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Sidebar from './Sidebar';
import './page.css'
import {Modal,Card, Typography, FormControl, InputLabel,  MenuItem, TextField, Button, Select,Grid } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ReportIcon from '@mui/icons-material/Report';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// KaTeX dependency
import "katex/dist/katex.css";
// Quill dependency
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
// MathQuill dependency
import "./jquery";
import "@edtr-io/mathquill/build/mathquill.js";
import "@edtr-io/mathquill/build/mathquill.css";
// mathquill4quill include
import mathquill4quill from "mathquill4quill";
import "mathquill4quill/mathquill4quill.css";
import parse from 'html-react-parser';
import Latex from 'react-latex-next';
import ClearIcon from '@mui/icons-material/Clear';
import './default.css'
import axios from 'axios';
//APIs
import { fetchCategoryAsync } from '../redux/reducer/category/categorySlice';
import { fetchCourseByCategoryAsync } from '../redux/reducer/courses/courseSlice';
import { fetchCourseAsync} from '../redux/reducer/courses/courseSlice';
import { fetchYearAsync } from '../redux/reducer/years/yearSlice';
import { fetchTopicsByCourseIdAsync } from '../redux/reducer/topics/topicSlice';
import { fetchQuestionAsync } from '../redux/reducer/questions/questionSlice';
import { createQuestionAsync } from '../redux/reducer/questions/questionSlice';
import { deleteQuestionAsync } from '../redux/reducer/questions/questionSlice';
import { updateQuestionAsync } from '../redux/reducer/questions/questionSlice';
import katex from "katex";
window.katex = katex;

const Question = () => {

  const { appId} = useParams();
  const dispatch = useDispatch();
  const menuItems = ['Option A', 'Option B', 'Option C', 'Option D', 'Option E','Option F','Option G'];

  const categories = useSelector((state) => state.category.list);
  const courses = useSelector((state) => state.course.list);
  const topics = useSelector((state) => state.topic.list);
  const years = useSelector((state) => state.year.list);
  const questions = useSelector((state) => state.question.list);
  const loading = useSelector((state) => state.question.loading);

  const [open, setOpen] = useState(false);
  const [SelectedCourse, setSelectedCourse] = useState(null);
  const [SelectedCategory, setSelectedCategory] = useState(null);
  const [SelectedYear, setSelectedYear] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [SelectedTopic, setSelectedTopic] = useState(null);
  const [fetchedQuestion, setFetchedQuestion] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [SelectedQuestion, setSelectedQuestion] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [saved, setSaved] = useState(false); // New state for saved

  //Add Question
  const [questionasked, setQuestionAsked] = useState('');
  const [questionType, setQuestionType] = useState('subjective');
  const [answerType, setAnswerType] = useState('text');
  const [options, setOptions] = useState([""]);
  const [answer, setAnswer] = useState(menuItems[0]);
  const [solution, setSolution] = useState('');
  const reactQuillRef = useRef(null);

  useEffect(() => {
    if(reactQuillRef.current){
      const enableMathQuillFormulaAuthoring = mathquill4quill({ Quill, katex });
    enableMathQuillFormulaAuthoring(reactQuillRef.current.editor, {
      displayHistory: true,
      operators: [
        ["\\pm", "\\pm"],
        ["\\sqrt{x}", "\\sqrt"],
        ["\\sqrt[3]{x}", "\\sqrt[3]{}"],
        ["\\sqrt[n]{x}", "\\nthroot"],
        ["\\frac{x}{y}", "\\frac"],
        ["\\sum^{s}_{x}{d}", "\\sum"],
        ["\\prod^{s}_{x}{d}", "\\prod"],
        ["\\coprod^{s}_{x}{d}", "\\coprod"],
        ["\\int^{s}_{x}{d}", "\\int"],
        ["\\binom{n}{k}", "\\binom"],
        ['E = mc^2', 'E = mc^2'],
        ['\\Delta x = \\frac{v_f - v_i}{t}', '\\Delta x = \\frac{v_f - v_i}{t}'],
        ['x = \\frac{-b \\pm \\sqrt{b^2 - 4ac}}{2a}', 'x = \\frac{-b \\pm \\sqrt{b^2 - 4ac}}{2a}'],
        ['\\vec{F} = m\\vec{a}', '\\vec{F} = m\\vec{a}'],
        ['\\sin^2{x} + \\cos^2{x} = 1', '\\sin^2{x} + \\cos^2{x} = 1'],
        ['\\frac{d}{dx}e^x = e^x', '\\frac{d}{dx}e^x = e^x'],
        ['\\int_{a}^{b} f(x) dx', '\\int_{a}^{b} f(x) dx'],
        ['\\ln{x}', '\\ln'],
        ["\\alpha", "\\alpha"],
        ["\\beta", "\\beta"],
        ["\\gamma", "\\gamma"],
        ["\\delta", "\\delta"],
        ["\\epsilon", "\\epsilon"],
        ['\\sin{x}', '\\sin'],
        ['\\cos{x}', '\\cos'],
        ['\\tan{x}', '\\tan'],
        ['\\cot{x}', '\\cot'],
        ['\\sec{x}', '\\sec'],
        ['\\csc{x}', '\\csc'],
        ['\\arcsin{x}', '\\arcsin'],
        ['\\arccos{x}', '\\arccos'],
        ['\\arctan{x}', '\\arctan'],
        ['\\sinh{x}', '\\sinh'],
        ['\\cosh{x}', '\\cosh'],
        ['\\tanh{x}', '\\tanh'],
        ['\\coth{x}', '\\coth'],
        ['\\sech{x}', '\\sech'],
        ['\\csch{x}', '\\csch'],
        ['\\arcsinh{x}', '\\arcsinh'],
        ['\\arccosh{x}', '\\arccosh'],
        ['\\arctanh{x}', '\\arctanh'],
        ['\\arccoth{x}', '\\arccoth'],
        ['\\arcsech{x}', '\\arcsech'],
        ['\\arccsch{x}', '\\arccsch']
      ],
      displayDeleteButtonOnHistory: true
    });
    }
  }, [reactQuillRef.current]);

  useEffect(() => {
    dispatch(fetchCategoryAsync(appId));
  }, [dispatch, appId]);

  useEffect(() => {
    dispatch(fetchYearAsync(appId));
  }, [dispatch, appId]);

  useEffect(() => {
    if (isFetching) {
      dispatch(fetchQuestionAsync(appId,SelectedCategory,SelectedYear,SelectedCourse,SelectedTopic));
      setIsFetching(false);
    }
  }, [dispatch, isFetching]);

 

  if (!courses) {
    return <Box>Loading...</Box>;
  }


   const handleQuestionChange = (newContent) => {
    setQuestionAsked(newContent);
  };
  const handleOptionChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
  };

  const addOptionField = () => {
    setOptions([...options, '']);
  };

  const removeOptionField = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
  };

  const handleCorrectionChange = (newContent) => {
    setSolution(newContent);
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const openEditModal = (question) => {
    setSelectedQuestion(question)
    console.log(SelectedQuestion)
    setIsEditModalOpen(true);
  };

  // Function to close the edit modal
  const closeEditModal = () => {
    setSelectedQuestion(null)
    setIsEditModalOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setSaved(false)
    setOptions(['Option A'])
    setQuestionAsked('');
    setQuestionType('subjective');
    setAnswer(menuItems[0]);
    setSolution('');
    
  };
  const handleOpenDeleteModal = (question) => {
    setSelectedQuestion(question);
    console.log(SelectedQuestion)
    console.log(appId)
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
 

  const handleCourseSelection = (courseId) => {
    setSelectedCourse(courseId._id);
    console.log(courseId._id)
    dispatch(fetchTopicsByCourseIdAsync(appId, courseId._id));
  };
  const handleTopicSelection = (topicId) => {
    setSelectedTopic(topicId._id);
    console.log(topicId._id)
  };
  const handleYearSelection = (yearId) => {
    setSelectedYear(yearId._id);
    console.log(yearId._id)
  };
  const handleCategorySelection = (categoryId) => {
    setSelectedCategory(categoryId._id);
    console.log(categoryId._id)
    dispatch(fetchCourseByCategoryAsync(appId,categoryId._id))
  };


  
  const getSelectedCourseName = () => {
    const course = courses.find((course) => course._id === SelectedCourse);
    return course ? course.name.substring(0,15) + '...' : 'Select a Course';
  };
  const getSelectedCourseCode = () => {
    const course = courses.find((course) => course._id === SelectedCourse);
    return course ? course.code : 'Select a Course';
  };
  const getCategory = () => {
    const category = categories.find((category) => category._id === SelectedCategory);
    return category ? category.category : 'Select a Category';
  };
  const getYear = () => {
    const year = years.find((year) => year._id === SelectedYear);
    return year ? year.year : 'Select a Year';
  };
  const getTopic = () => {
    const topic = topics.find((topic) => topic._id === SelectedTopic);
    return topic ? topic.title.substring(0,15) + '...' : 'Select a Topic';
  };

  //Fetch Questions
  const handleFetchQuestions=()=>{
    if(SelectedYear && SelectedCourse && SelectedTopic){
        dispatch(fetchQuestionAsync(appId,SelectedCategory,SelectedYear,SelectedCourse,SelectedTopic))
       // console.log(`${appId} ${SelectedCourse} ${SelectedYear}${SelectedTopic}`)
        setFetchedQuestion(questions)
        setIsFetching(true)
    }else{
        console.log('Select a course')
    }
  }
  const qaddOptionField = () => {
    setSelectedQuestion((prevQuestion) => ({
      ...prevQuestion,
      options: [...prevQuestion.options, ''] // Add an empty option to the options array
    }));
  };

  const qremoveOptionField = (index) => {
    setSelectedQuestion((prevQuestion) => {
      const updatedOptions = [...prevQuestion.options];
      updatedOptions.splice(index, 1); // Remove the option at the specified index
      return {
        ...prevQuestion,
        options: updatedOptions
      };
    });
  };
  const handleCreateQuestion= async(e)=>{
    e.preventDefault()
   if(SelectedCourse && SelectedYear && SelectedTopic && questionasked && answer ){
    const div = document.createElement("div");
    const div2 = document.createElement("div");
    div.innerHTML = questionasked;
    div2.innerHTML  = solution;

    const richTextElements = div.getElementsByClassName("ql-editor");
    for (let i = 0; i < richTextElements.length; i++) {
      const richTextContent = richTextElements[i].innerHTML;
      richTextElements[i].innerHTML = richTextContent;
    }
    const formulaElements = div.getElementsByClassName("ql-formula");
    for (let i = 0; i < formulaElements.length; i++) {
      const dataValue = formulaElements[i].getAttribute("data-value");
      const latex = dataValue ? `$$${dataValue}$$` : "";
      formulaElements[i].textContent = latex;
    }
    if (selectedImage) {
      const formData = new FormData();
      formData.append('file', selectedImage);
      formData.append('upload_preset', 'istudy');
      try {
        const response = await axios.post(
          'https://api.cloudinary.com/v1_1/istudyapp/image/upload',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        // Handle the response from Cloudinary after successful upload
        const image = response.data.secure_url

    const questionData = {
      questionasked,
      questionType,
      options,
      answer,
      solutionType:answerType,
      solution:image,
    };
    // Call the onAddQuestion prop to add the question
    dispatch(createQuestionAsync({appId,categoryId:SelectedCategory,yearId:SelectedYear, courseId:SelectedCourse,topicId:SelectedTopic,questionData:questionData }))
    .then(()=>{
      setSaved(true)
      setSelectedImage(null)
    })
    dispatch(fetchQuestionAsync(appId,SelectedCategory,SelectedCategory,SelectedYear,SelectedCourse,SelectedTopic))
    console.log(questionData)
  }catch(err){

  }
}else{
  const questionData = {
    questionasked,
    questionType,
    options,
    answer,
    solutionType:answerType,
    solution,
  };
  // Call the onAddQuestion prop to add the question
  dispatch(createQuestionAsync({appId,categoryId:SelectedCategory,yearId:SelectedYear, courseId:SelectedCourse,topicId:SelectedTopic,questionData:questionData }))
  .then(()=>{
    setSaved(true)
    setSelectedImage(null)
  })
  dispatch(fetchQuestionAsync(appId,SelectedCategory,SelectedYear,SelectedCourse,SelectedTopic))
  console.log(questionData)
      }
   }else{
    alert("Select and input the required fields")
    setSaved(false)
   }
   dispatch(fetchQuestionAsync(appId,SelectedCategory,SelectedYear,SelectedCourse,SelectedTopic))
  }
  const handleDeleteQuestion = () => {
    if (SelectedQuestion) {
      dispatch(deleteQuestionAsync({appId,questionId:SelectedQuestion}));
      console.log(`deleted ${SelectedQuestion}`)
      dispatch(fetchQuestionAsync(appId,SelectedCategory,SelectedYear,SelectedCourse,SelectedTopic))
      handleCloseDeleteModal();
    }
  };

  const handleUpdateQuestion = async (event) =>{
    event.preventDefault();
    
    if(SelectedQuestion){
      // Check if the question type is "objective"
    if (SelectedQuestion.questionType === "subjective") {
      // Set the options array to null
      setSelectedQuestion(prevState => ({
        ...prevState,
        options: [""]
      }));
    }
    if (selectedImage) {
      const formData = new FormData();
      formData.append('file', selectedImage);
      formData.append('upload_preset', 'istudy');
      try {
        const response = await axios.post(
          'https://api.cloudinary.com/v1_1/istudyapp/image/upload',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        // Handle the response from Cloudinary after successful upload
        const image = response.data.secure_url;
      
        console.log(SelectedQuestion.solution)
        console.log(image)
        SelectedQuestion.solution = response.data.secure_url;
              // Call the onAddQuestion prop to add the question
    dispatch(updateQuestionAsync({appId,categoryId:SelectedCategory,yearId:SelectedYear, courseId:SelectedCourse,topicId:SelectedTopic,questionId:SelectedQuestion._id,questionData:SelectedQuestion }))
    .then((updatedQuestion) => {
      if (updatedQuestion) {
        // Update the 'notes' state variable with the updated note data
        setSelectedQuestion(updatedQuestion);
        dispatch(fetchQuestionAsync(appId,SelectedCategory,SelectedYear,SelectedCourse,SelectedTopic))
        setSaved(true);
        setSelectedImage(null)
      }
    })
    .catch((error) => {
      console.error(error);
      // Handle error if necessary
    });
    dispatch(fetchQuestionAsync(appId,SelectedCategory,SelectedYear,SelectedCourse,SelectedTopic))
    console.log(SelectedQuestion)
    handleFetchQuestions()
      }
      catch(err){
        console.log(err)
      }
    }
    else{
      dispatch(updateQuestionAsync({appId,categoryId:SelectedCategory,yearId:SelectedYear, courseId:SelectedCourse,topicId:SelectedTopic,questionId:SelectedQuestion._id,questionData:SelectedQuestion }))
      .then((updatedQuestion) => {
        if (updatedQuestion) {
          // Update the 'notes' state variable with the updated note data
          setSelectedQuestion(updatedQuestion);
          dispatch(fetchQuestionAsync(appId,SelectedYear,SelectedCourse,SelectedTopic))
          setSaved(true);
          setSelectedImage(null)
        }
      })
      .catch((error) => {
        console.error(error);
        // Handle error if necessary
      });
      handleFetchQuestions()
    dispatch(fetchQuestionAsync(appId,SelectedYear,SelectedCourse,SelectedTopic))
 
    }

    }
    setSelectedImage(null)
  }
 
  return (
    <Box>
        <Sidebar />
        {/**Add Question Modal */}
       <Button variant='contained' align="center" color='secondary' style={{marginTop:"1rem",marginBottom:"3rem"}} onClick={handleOpen}><AddIcon/> Past Question</Button>
        <Modal open={open} onClose={handleClose}>
            <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "100%",height:"90%", backgroundColor: 'white', borderRadius: 8, padding: 16 ,overflow: 'auto'}}>   
            <Typography variant="h6" style={{marginBottom:"0.5rem"}}>Add Question</Typography>
            <Box style={{display:"flex",gap:"6px",alignItems:"center",justifyContent:"center",paddingBottom:"1rem"}} >

            <Dropdown>
                    <Dropdown.Toggle  id="dropdown-basic" style={{background:"transparent",fontSize:"10px",fontWeight:"500",border:"0.2em solid grey",color:"black" }}>
                    {getCategory()}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{fontSize:"15px" }}>
                    {categories.map((categoryId) => (
                                <Dropdown.Item
                                key={categoryId._id}
                                onClick={() => handleCategorySelection(categoryId)}
                                href="#">{categoryId.category}</Dropdown.Item>  
                        ))}
                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown>
                    <Dropdown.Toggle  id="dropdown-basic" style={{background:"transparent",fontSize:"10px",fontWeight:"500",border:"0.2em solid grey",color:"black" }}>
                    {getSelectedCourseName()}
                    </Dropdown.Toggle>
                    {SelectedCategory && (
                    <Dropdown.Menu style={{fontSize:"15px" }}>
                    {courses.map((courseId) => (
                        
                                <Dropdown.Item
                                key={courseId._id}
                                onClick={() => handleCourseSelection(courseId)}
                                href="#">{courseId.name}</Dropdown.Item>  
                        ))}
                        </Dropdown.Menu>
                    )}
                    </Dropdown>

                    <Dropdown>
                    <Dropdown.Toggle  id="dropdown-basic" style={{background:"transparent",fontSize:"10px",fontWeight:"500",border:"0.2em solid grey",color:"black" }}>
                    {getYear()}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{fontSize:"15px" }}>
                    {years.map((yearId) => (
                                <Dropdown.Item
                                key={yearId._id}
                                onClick={() => handleYearSelection(yearId)}
                                href="#">{yearId.year}</Dropdown.Item>  
                        ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle  id="dropdown-basic" style={{backgroundColor:"transparent",fontSize:"10px",fontWeight:"500",border:"0.2em solid grey",color:"black" }}>
                            {getTopic()}
                        </Dropdown.Toggle>
                        {SelectedCourse && (
                        <Dropdown.Menu style={{fontSize:"13px" }}>
                            {topics.map((topic) => (       
                            <Dropdown.Item
                            key={topic._id}
                            onClick={() => handleTopicSelection(topic)}
                            href="#">{topic.title}</Dropdown.Item>  
                            ))}
                        </Dropdown.Menu>
                          )}
                </Dropdown>
                    </Box>
                  <ReactQuill
                    ref={reactQuillRef}
                    id="editor"
                    margin="normal"
                    fullWidth 
                    style={{height:"40%", overflow: 'auto'}}
                    modules={{
                      formula: true,
                      toolbar: [
                        ["video","bold", "italic", "strike", "underline", "formula","image","color","code-block"],
                        [{ script: "sub" }, { script: "super" }],
                        ["link"],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ color: [] }, { background: [] }],
                        ['blockquote'],
                        [{ list: "ordered" }, { list: "bullet" }],
                        ['clean']
                      ]
                    }}
              value={questionasked}
              onChange={handleQuestionChange}
              placeholder="Enter question"
              theme="snow"
               />
            <Typography>Question Type</Typography>
            <FormControl>
            <Select value={questionType} required
            labelId="question-type-label"
              id="question-type" onChange={(event)=>setQuestionType(event.target.value)}>
              <MenuItem value="subjective">Subjective</MenuItem>
              <MenuItem value="objective">Objective</MenuItem>
            </Select>
            </FormControl>
          {questionType === 'objective' && (
            <Box style={{paddingTop:"1rem"}}>
                <Typography>Options</Typography>
              
              <Box style={{ display: 'flex',flexWrap:"wrap",}}>
            {options.map((option, index) => (
              <Box key={index} style={{ display: 'flex',marginRight: '10px', marginBottom: '10px', }}>
                <TextField
                  label={`Option ${index + 1}`}
                  
                  value={option}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                  required
                />
                {index > 0 && (
                    <ClearIcon onClick={() => removeOptionField(index)} />
                )}
              </Box>
            ))}
             </Box>
             <Button variant="contained" color="primary" onClick={addOptionField}>
             <AddIcon/> Option
            </Button>
            <Box>
            <Typography>Answer</Typography>

              <Select
                id="answer"
                value={answer} style={{marginTop:"1rem"}}
                onChange={(e) => setAnswer(e.target.value)}
              >
                {menuItems.slice(0, options.length).map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          )}   
                 {questionType === "subjective" &&(
                     <TextField
                     id="answer"
                     label="Answer"
                     style={{marginLeft:"1rem"}}
                     value={answer}
                     onChange={(e) => setAnswer(e.target.value)}
                   />
                 )}


            <Typography>Solution Type</Typography>
            <FormControl>
            <Select value={answerType} required
            labelId="question-type-label"
              id="question-type" onChange={(event)=>setAnswerType(event.target.value)}>
              <MenuItem value="text">Text</MenuItem>
              <MenuItem value="image">Image</MenuItem>
            </Select>
            </FormControl>
            <Typography>Solution</Typography>

            {answerType === "text" &&(
               <ReactQuill
               ref={reactQuillRef}
               id="editor"
               margin="normal"
               fullWidth 
               style={{height:"30%", overflow: 'auto',marginBottom:"0.5rem",marginTop:"0.5rem"}}
               modules={{
                 formula: true,
                 toolbar: [
                  ["video","bold", "italic", "strike", "underline", "formula","image","color","code-block"],
                  [{ script: "sub" }, { script: "super" }],
                  ["link"],
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  [{ color: [] }, { background: [] }],
                  ['blockquote'],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ['clean']
                 ]
                 
               }}
            value={solution}
            onChange={handleCorrectionChange}
            placeholder="Enter Solution"
            theme="snow"
             />
            )}

       {answerType === "image" &&(
         <input type="file" accept="image/*" style={{margin:"0.8rem"}} onChange={handleImageChange}  />
       )}
            
              
              <Button variant="contained" style={{background:"#540a61"}} onClick={handleCreateQuestion}>
              {loading ? 'Saving...' : saved ? 'Saved Successfully' : 'Save'}
              </Button>
              <Button variant="contained" style={{background:"red",marginLeft:"1rem"}} onClick={handleClose}>
                Cancel
              </Button>
            </Box>
          </Modal>

        {/**Page */}
        <Box>
        <Typography variant='h5' fontWeight="700">Past Questions</Typography>
        <Box style={{padding:"1rem",borderRadius:"5px"}}>
            <Box style={{display:"flex",gap:"6px",alignItems:"center",justifyContent:"center",paddingBottom:"1rem"}}>

            <Dropdown>
                    <Dropdown.Toggle  id="dropdown-basic" style={{background:"transparent",fontSize:"10px",fontWeight:"500",border:"0.2em solid grey",color:"black" }}>
                    {getCategory()}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{fontSize:"15px" }}>
                    {categories.map((categoryId) => (
                                <Dropdown.Item
                                key={categoryId._id}
                                onClick={() => handleCategorySelection(categoryId)}
                                href="#">{categoryId.category}</Dropdown.Item>  
                        ))}
                        </Dropdown.Menu>
                    </Dropdown>
                {/**Courses */}
                <Dropdown >
                    <Dropdown.Toggle  id="dropdown-basic" style={{background:"transparent",backgroundColor:"transparent",fontSize:"10px",fontWeight:"500",border:"0.2em solid grey",color:"black" }}>
                    {getSelectedCourseName()}
                    </Dropdown.Toggle>
                    {SelectedCategory && (
                    <Dropdown.Menu style={{fontSize:"13px" }}>
                    {courses.map((courseId) => (
                        
                                <Dropdown.Item
                                key={courseId._id}
                                onClick={() => handleCourseSelection(courseId)}
                                href="#">{courseId.name}</Dropdown.Item>  
                        ))}
                        </Dropdown.Menu>
                    )}
                    </Dropdown>
                      {/**Year */}
                    <Dropdown >
                    <Dropdown.Toggle  id="dropdown-basic" style={{background:"transparent",fontSize:"10px",fontWeight:"500",border:"0.2em solid grey",color:"black" }}>
                    {getYear()}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{fontSize:"15px" }}>
                    {years.map((yearId) => (
                        
                                <Dropdown.Item
                                key={yearId._id}
                                onClick={() => handleYearSelection(yearId)}
                                href="#">{yearId.year}</Dropdown.Item>  
                        ))}
                        </Dropdown.Menu>
                    </Dropdown>
                      {/**Topics */}
                    <Dropdown >
                        <Dropdown.Toggle  id="dropdown-basic" style={{ background:"transparent",fontSize:"10px",fontWeight:"500",border:"0.2em solid grey",color:"black" }}>
                            {getTopic()}
                        </Dropdown.Toggle>
                        {SelectedCourse && (
                        <Dropdown.Menu style={{fontSize:"13px" }}>
                            {topics.map((topic) => (  
                              <Dropdown.Item
                                key={topic._id}
                                onClick={() => handleTopicSelection(topic)}
                                href="#">{topic.title}
                              </Dropdown.Item>  
                            ))}
                        </Dropdown.Menu>
                        )}
                    </Dropdown>
                </Box>
            <Button variant='contained' style={{background:"#540a61"}} onClick={handleFetchQuestions} >Fetch Past Questions</Button>
        </Box>
     
          <Card style={{marginTop:"2rem"}}>
          <TableContainer>
          <Table>
                <TableHead>
                    <TableRow style={{fontWeight:"600",borderBottom:"3px solid #8a898f"}}>
                        <TableCell>S/N</TableCell>
                        <TableCell>Question</TableCell>
                        <TableCell>Course Code</TableCell>
                        <TableCell>Year</TableCell>
                        <TableCell>Topic</TableCell>
                        <TableCell>Answer</TableCell>
                        <TableCell>Solution</TableCell>
                        <TableCell colSpan="2">Actions</TableCell>
                    </TableRow>
                </TableHead>
                 <TableBody >
                 {loading ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : fetchedQuestion ? (
                questions.length > 0 ? (
                    questions.map((question,index) => (
                        <TableRow key={question._id} style={{fontWeight:"600",borderBottom:"3px solid #8a898f"}}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell><Latex>{question.questionasked}</Latex></TableCell>
                            <TableCell>{getSelectedCourseCode()}</TableCell>
                            <TableCell>{getYear()}</TableCell>
                            <TableCell>{getTopic()}</TableCell>
                            <TableCell><Latex>{question.answer}</Latex></TableCell>
                            <TableCell><Latex>{question.solution}</Latex></TableCell>
                            <TableCell>
                             <Button variant='contained' style={{background:"blue"}} onClick={() => openEditModal(question)}><EditCalendarIcon /> </Button> &nbsp;
                              <Button variant='contained' style={{background:"red"}} onClick={() => handleOpenDeleteModal(question._id)}><DeleteForeverIcon background="red" /> </Button>
                              </TableCell>
                        </TableRow>
                    ))
                  ) : (
                        <TableRow>
                          <TableCell colSpan={7}  align="center">
                            <Typography fontWeight="500">Oops! You have no question for this Option</Typography>
                          </TableCell>
                        </TableRow>
                  )):(
                    <TableRow>
                      <TableCell colSpan={7}  align="center">
                        <Typography fontWeight="400">Please Select an Option</Typography>
                      </TableCell>
                  </TableRow>
                  )
                }
                </TableBody>
            </Table>
          </TableContainer>
          </Card>

          <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-confirmation-modal"
        aria-describedby="delete-confirmation-modal-description"
      >
        <Box style={{ 
          position: 'absolute', 
          top: '50%', left: '50%',
          transform: 'Translate(-50%, -50%)', 
          width: 400,
          backgroundColor: 'white', 
          borderRadius: 8, padding: 16 }}>
          <Box align="center">
            <ReportIcon/>
            <Typography fontSize="16px" fontWeight="600" id="delete-confirmation-modal-description">
              Are you sure you want to delete this question?
            </Typography>
          </Box>
          <Box style={{display:"flex",gap:"6px",alignItems:"center",justifyContent:"center",margin:"1rem"}}>
          <Button variant='contained' onClick={handleDeleteQuestion}>Yes,Delete</Button>
          <Button variant='contained' color='secondary' onClick={handleCloseDeleteModal}>Cancel</Button>
          </Box>
        </Box>
      </Modal>
        </Box>

        
          {/**Edit Question Modal */}

          {SelectedQuestion && (
            <Modal open={isEditModalOpen} onClose={closeEditModal}>
            <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "100%",height:"80%", backgroundColor: 'white', borderRadius: 8, padding: 16 ,overflow: 'auto'}}>   
            <Typography variant="h6" style={{marginBottom:"0.5rem"}}>Edit Question</Typography>
            <Box style={{display:"flex",gap:"6px",alignItems:"center",justifyContent:"center",paddingBottom:"1rem"}} >
            <Dropdown>
                    <Dropdown.Toggle  id="dropdown-basic" style={{background:"transparent",fontSize:"10px",fontWeight:"500",border:"0.2em solid grey",color:"black" }}>
                    {getCategory()}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{fontSize:"15px" }}>
                    {categories.map((categoryId) => (
                                <Dropdown.Item
                                key={categoryId._id}
                                onClick={() => handleCategorySelection(categoryId)}
                                href="#">{categoryId.category}</Dropdown.Item>  
                        ))}
                        </Dropdown.Menu>
                    </Dropdown> 
                    <Dropdown>
                    <Dropdown.Toggle  id="dropdown-basic" style={{background:"transparent",fontSize:"10px",fontWeight:"500",border:"0.2em solid grey",color:"black" }}>
                    {getSelectedCourseName()}
                    </Dropdown.Toggle>
                    {SelectedCategory && (
                    <Dropdown.Menu style={{fontSize:"15px" }}>
                    {courses.map((courseId) => (
                        
                                <Dropdown.Item
                                key={courseId._id}
                                onClick={() => handleCourseSelection(courseId)}
                                href="#">{courseId.name}</Dropdown.Item>  
                        ))}
                        </Dropdown.Menu>
                    )}
                    </Dropdown>

                    <Dropdown>
                    <Dropdown.Toggle  id="dropdown-basic" style={{background:"transparent",fontSize:"10px",fontWeight:"500",border:"0.2em solid grey",color:"black" }}>
                    {getYear()}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{fontSize:"15px" }}>
                    {years.map((yearId) => (
                                <Dropdown.Item
                                key={yearId._id}
                                onClick={() => handleYearSelection(yearId)}
                                href="#">{yearId.year}</Dropdown.Item>  
                        ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle  id="dropdown-basic" style={{backgroundColor:"transparent",fontSize:"10px",fontWeight:"500",border:"0.2em solid grey",color:"black" }}>
                            {getTopic()}
                        </Dropdown.Toggle>
                        {SelectedCourse && (
                        <Dropdown.Menu style={{fontSize:"13px" }}>
                            {topics.map((topic) => (       
                            <Dropdown.Item
                            key={topic._id}
                            onClick={() => handleTopicSelection(topic)}
                            href="#">{topic.title}</Dropdown.Item>  
                            ))}
                        </Dropdown.Menu>
                          )}
                </Dropdown>
            </Box>
            
                  <ReactQuill
               ref={reactQuillRef}
               id="editor"
               margin="normal"
               fullWidth 
               style={{height:"30%", overflow: 'auto',marginBottom:"0.5rem",marginTop:"0.5rem"}}
               modules={{
                 formula: true,
                 toolbar: [
                  ["video","bold", "italic", "strike", "underline", "formula","image","color","code-block"],
                  [{ script: "sub" }, { script: "super" }],
                  ["link"],
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  [{ color: [] }, { background: [] }],
                  ['blockquote'],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ['clean']
                 ]
                 
               }}
               value={SelectedQuestion.questionasked}
               onChange={(value) => {
                setQuestionAsked(value);
              }}
              placeholder="Enter question"
              theme="snow"
               />
            <Typography>Question Type</Typography>
            <FormControl>
            <Select value={SelectedQuestion.questionType} required
            labelId="question-type-label"
              id="question-type"
              onChange={(event) => setSelectedQuestion({ ...SelectedQuestion, questionType: event.target.value })}>
              <MenuItem value="subjective">Subjective</MenuItem>
              <MenuItem value="objective">Objective</MenuItem>
            </Select>
            </FormControl>
          {SelectedQuestion.questionType === 'objective' && (
            <Box style={{paddingTop:"1rem"}}>
                <Typography>Options</Typography>
              
              <Box style={{ display: 'flex',flexWrap:"wrap",}}>
            {SelectedQuestion.options.map((option, index) => (
              <Box key={index} style={{ display: 'flex',marginRight: '10px', marginBottom: '10px', }}>
                <TextField
                          label={`Option ${index + 1}`}

                      value={SelectedQuestion.options[index]}
                      onChange={(e) => {
                        const updatedOptions = [...SelectedQuestion.options];
                        updatedOptions[index] = e.target.value;
                        setSelectedQuestion(prevState => ({
                          ...prevState,
                          options: updatedOptions
                        }));
                      }}
                />
                {index > 0 && (
                    <ClearIcon onClick={() => qremoveOptionField(index)} />
                )}
              </Box>
            ))}
             </Box>
             <Button variant="contained" color="primary" onClick={qaddOptionField}>
             <AddIcon/> Option
            </Button>
            <Box>
            <Select
            id="answer"
            value={SelectedQuestion.answer}
            style={{ marginTop: "1rem" }}
            onChange={(e) => setSelectedQuestion({ ...SelectedQuestion, answer: e.target.value })}
          >
                {menuItems.slice(0, SelectedQuestion.options.length).map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          )}   
                 {SelectedQuestion.questionType === "subjective" &&(
                     <TextField
                     id="answer"
                     label="Answer"
                    
                     style={{marginLeft:"1rem"}}
                     value={SelectedQuestion.answer}
                     onChange={(e) => setSelectedQuestion({ ...SelectedQuestion, answer: e.target.value })}
                   />
                 )}


            <Typography>Solution Type</Typography>
            <FormControl>
            <Select value={SelectedQuestion.solutionType} required
            labelId="question-type-label"
              id="question-type"  onChange={(event) => setSelectedQuestion({...SelectedQuestion, solutionType: event.target.value})}>
              <MenuItem value="text">Text</MenuItem>
              <MenuItem value="image">Image</MenuItem>
            </Select>
            </FormControl>
            <Typography>Solution</Typography>

            {SelectedQuestion.solutionType === "text" &&(
               <ReactQuill
               ref={reactQuillRef}
               id="editor"
               margin="normal"
               fullWidth 
               style={{height:"30%", overflow: 'auto',marginBottom:"0.5rem",marginTop:"0.5rem"}}
               modules={{
                 formula: true,
                 toolbar: [
                  ["video","bold", "italic", "strike", "underline", "formula","image","color","code-block"],
                  [{ script: "sub" }, { script: "super" }],
                  ["link"],
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  [{ color: [] }, { background: [] }],
                  ['blockquote'],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ['clean']
                 ]
                 
               }}
            value={SelectedQuestion.solution}
            onChange={(value) => {
              setSelectedQuestion((prevQuestion) => ({
                ...prevQuestion,
                solution: value
              }));
            }}
            placeholder="Enter Solution"
            theme="snow"
             />
            )}

       {SelectedQuestion.solutionType === "image" &&(
        <Box>
          <img src={SelectedQuestion.solution} alt="question pic" style={{width:100, height:100}}/>
         <input type="file" accept="image/*" style={{margin:"0.8rem"}} onChange={handleImageChange}  />
        </Box>
       )}    <Button variant="contained" style={{background:"#540a61"}} onClick={handleUpdateQuestion}>
              {loading ? 'Saving...' : saved ? 'Saved Successfully' : 'Save'}
              </Button>
              <Button variant="contained" style={{background:"red",marginLeft:"1rem"}} onClick={closeEditModal}>
                Cancel
              </Button>
            </Box>
          </Modal>
          )}
    </Box>
  )
}

export default Question
