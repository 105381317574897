import { createSlice } from '@reduxjs/toolkit';
import { getAcivationCodes,createActivationCode, takeActivationCode,} from '../auth/api';

const codeSlice = createSlice({
  name: 'code',
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    takenCode: (state) => {
      state.loading = false;
      state.error = null;
    },
    setCode: (state, action) => {
      state.loading = false;
      state.error = null;
      state.list = action.payload;
    },
    createCodeSuccess:(state,action)=>{
        state.list.push(action.payload);
        state.loading = false;
        state.error = null;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setLoading, setCode, createCodeSuccess,setError,takenCode } = codeSlice.actions;

export const fetchCodeAsync = (appId) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const codes = await getAcivationCodes(appId);
    console.log(codes)
    dispatch(setCode(codes));
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const takeCodeAsync = (appId,code) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const response = await takeActivationCode(appId,code);
    console.log(response)
    dispatch(takenCode());
  } catch (error) {
    dispatch(setError(error.message));
  }
};
export const createCodeAsync = (appId,codeCount) => async (dispatch) => {
    try {
      dispatch(setLoading());
      const code = await createActivationCode(appId,codeCount);
      dispatch(createCodeSuccess(code));
      console.log(code)
    } catch (error) {
      dispatch(setError(error.message));
    }
  };


export default codeSlice.reducer;
