
import axios from "axios"

const API_ENDPOINT = "https://api.istudyunleash.com"
const token = localStorage.getItem('token')

export const api = axios.create({
    baseURL: API_ENDPOINT,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type":"multipart/form-data"
    },
  });
  
  export const api2 = axios.create({
    baseURL: API_ENDPOINT,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type":"application/json"
    },
  });
  
  //@APPS
  //fetch apps
  export const getApps = async () => {
    const response = await api.get('/api/admin/apps');
    console.log(response.data.apps)
    return response.data.apps;
  };  
  //create app
  export const createApp = async (appData) => {
    const response = await api.post('/api/admin/app', appData)
    console.log(response.data)
    return response.data;
  };
  //fetch apps by Id
  export const getAppById = async (appId) => {
    const response = await api.get(`/api/admin/app/${appId}`);
   // console.log(response.data.app)
    return response.data.app;
  };
  
//@CATEGORIES
//getCategories

export const getCategory = async (appId) => {
  const response = await api.get(`/api/admin/app/${appId}/categories`);
  console.log(response.data)
  return response.data;
};
export const getCategoryById = async ({appId,categoryId}) => {
  const response = await api.get(`/api/admin/app/${appId}/catgeory/${categoryId}`);
  console.log(response.data.category)
  return response.data.category;
};
export const createCategory = async (appId, categoryData) => {
  try {
    const response = await api.post(`/api/admin/app/${appId}/category`,categoryData);
    console.log('Successful:', response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('Failed to create category.');
  }
  }
//Delet Category
export const deleteCategorybyId =  async (appId,categoryId) => {
  try{
    const response = await api.delete(`/api/admin/app/${appId}/category/${categoryId}`);
    console.log(response.data)
    return response.data;
  }catch(error){
    throw new Error('Failed to delete question')
  }
};

//UpdateCategory
export const UpdateCategory =  async (appId, categoryId,categoryData ) => {
  try{
    const response = await api.put(`/api/admin/app/${appId}/category/${categoryId}`, categoryData);
    console.log(response)
    return response;
  }catch(error){
    throw new Error('Failed to update category')
  }
};

//@COURSES
//getCourse
export const getCourseById = async ({appId,courseId}) => {
    const response = await api.get(`/api/admin/app/${appId}/course/${courseId}`);
    console.log(response.data.course)
    return response.data.course;
  };
//get courses
export const getCourse = async (appId) => {
    const response = await api.get(`/api/admin/app/${appId}/courses`);
   // console.log(response.data)
    return response.data;
  };
//get courses
export const getCoursesbyCategoryId = async (appId,categoryId) => {
  const response = await api.get(`/api/admin/app/${appId}/categories/${categoryId}/courses`);
  console.log(response.data)
  return response.data;
};


 //create course
 export const createCourse = async (appId, categoryId,courseData) => {
  try {
    const response = await api.post(`/api/admin/app/${appId}/${categoryId}/course`,courseData);
    console.log(response);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('Failed to create course.');
  }
  }
//UpdateCourse
export const updateCourse =  async (appId, courseId,courseData) => {
  try{
    const response = await api.put(`/api/admin/app/${appId}/course/${courseId}`, courseData);
    console.log(response)
    return response;
  }catch(error){
    throw new Error('Failed to update course')
  }
};
//Delet Course
export const deleteCoursebyId =  async (appId,courseId) => {
    try{
      const response = await api.delete(`/api/admin/app/${appId}/course/${courseId}`);
      console.log(response.data)
      return response.data;
    }catch(error){
      throw new Error('Failed to delete question')
    }
  };

//@Topics
//createTopics
export const createTopic = async (appId,categoryId, courseId, topicData) => {
  try {
    const response = await api.post(`/api/admin/app/${appId}/categories/${categoryId}/courses/${courseId}/topics`,topicData);
    console.log(response.data)
    return response.data;
  } catch (error) {
    throw new Error('Failed to create topic');
  }
};
//Fetch Topics
export const fetchTopicsByCourseId = async (appId,courseId) => {
  try{
    const response = await api.get(`/api/admin/app/${appId}/courses/${courseId}/topics`);
  return response.data;
  }catch(error){
    throw new Error('Failed to fetch topic')
  }
};

//edit Topic
export const updateTopic =  async (appId,categoryId, courseId, topicId, topicData ) => {
  console.log(topicData)
  try{
    const response = await api.put(`/api/admin/app/${appId}/categories/${categoryId}/courses/${courseId}/topics/${topicId}`, topicData);
    console.log(response)
    return response;
  }catch(error){
    throw new Error('Failed to update topic')
  }
};


export const deleteTopicbyId =  async (appId,courseId,topicId) => {
  try{
    const response = await api.delete(`/api/admin/app/${appId}/course/${courseId}/topic/${topicId}`);
    console.log(response.data)
    return response.data;
  }catch(error){
    throw new Error('Failed to delete question')
  }
};
//Notes
//create a new note
// Async Thunk for creating a note
export const createNotesByTopicId =  async (appId, courseId, topicId, noteData ) => {
  try{
    const response = await api.post(`/api/admin/app/${appId}/courses/${courseId}/topics/${topicId}/notes`, noteData);
    console.log(response.data)
    return response.data;
  }catch(error){
    throw new Error('Failed to create notes')
  }
};

//Fetch Topics
export const fetchNotesByTopicId = async (appId,courseId,topicId) => {
  try{
    const response = await api.get(`/api/admin/app/${appId}/courses/${courseId}/topics/${topicId}/notes`);
    console.log(response.data)
    return response.data;
  }catch(error){
    throw new Error('Failed to fetch note')
  }
};

export const deleteNotebyId =  async (appId,courseId,topicId,noteId) => {
  try{
    const response = await api.delete(`/api/admin/app/${appId}/course/${courseId}/topic/${topicId}/note/${noteId}`);
    console.log(response.data)
    return response.data;
  }catch(error){
    throw new Error('Failed to delete question')
  }
};

//edit notes
export const updateNote =  async (appId, courseId, topicId,noteId, noteData ) => {
  try{
    const response = await api.put(`/api/admin/app/${appId}/courses/${courseId}/topics/${topicId}/notes/${noteId}`, noteData);
    console.log(response)
    return response;
  }catch(error){
    throw new Error('Failed to update note')
  }
};


//@Questions

//get question
export const getQuestionById = async ({appId,questionId}) => {
  try{
    const response = await api.get(`/api/admin/app/${appId}/question/${questionId}`);
  console.log(response.data.course)
  return response.data.course;
  } catch(error){
    throw new Error('Failed to fetch Question')
  }
};

//edit Question
export const editQuestionbyOptionId =  async (appId,questionId, updatedQuestion) => {
  try{
    const response = await api.post(`/api/admin/app/${appId}/upatedquestion/${questionId}`, updatedQuestion);
    console.log(response.data)
    return response.data;
  }catch(error){
    throw new Error('Failed to create notes')
  }
};
//get Questions
export const fetchQuestions = async (appId,categoryId,yearId,courseId,topicId) => {
  try{
    const response = await api.get(`/api/admin/app/${appId}/categories/${categoryId}/years/${yearId}/courses/${courseId}/topics/${topicId}/questions`);
    console.log(response.data)
    return response.data;
  }catch(error){
    throw new Error('Failed to fetch Question')
  }
};

//edit question
export const updateQuestion =  async (appId,categoryId,yearId,courseId,topicId,questionId, questionData ) => {
  try{
    const response = await api2.put(`/api/admin/app/${appId}/categories/${categoryId}/years/${yearId}/courses/${courseId}/topics/${topicId}/question/${questionId}`, questionData);
    console.log(response)
    return response;
  }catch(error){
    throw new Error('Failed to update question')
  }
};

//get Flagged Questions
export const fetchFlaggedQuestions = async (appId) => {
  try{
    const response = await api.get(`/api/admin/app/${appId}/flagged-questions`);
    console.log(response.data)
    return response.data;
  }catch(error){
    throw new Error('Failed to fetch Flagged Question')
  }
};

//delete Flagged Questions
export const deleteFlaggedQuestionById = async (appId,questionId) => {
  try{
    const response = await api.delete(`/api/admin/app/${appId}/flagged-questions/${questionId}`);
    console.log(response.data)
    return response.data;
  }catch(error){
    throw new Error('Failed to delete Flagged Question')
  }
};

// Async Thunk for creating a note
export const createQuestionbyOptionId =  async (appId,categoryId,yearId, courseId, topicId, questionData) => {
  try{
    const response = await api2.post(`/api/admin/app/${appId}/categories/${categoryId}/years/${yearId}/courses/${courseId}/topics/${topicId}/questions`, questionData);
    console.log(response.data)
    return response.data;
  }catch(error){
    throw new Error('Failed to create notes')
  }
};
//Delete Question
export const deleteQuestionbyId =  async (appId,questionId) => {
  try{
    const response = await api.delete(`/api/admin/app/${appId}/question/${questionId}`);
    console.log(response.data)
    return response.data;
  }catch(error){
    throw new Error('Failed to delete question')
  }
};
//@YEARS
//get years
export const getYears = async (appId) => {
  const response = await api.get(`/api/admin/app/${appId}/years`);
 // console.log(response.data)
  return response.data;
};
//create course
export const createYear = async (appId, yearData) => {
try {
  const response = await api.post(`/api/admin/app/${appId}/years`, yearData);
  console.log(response.data)
  return response.data;
} catch (error) {
  console.error(error.response.data.message);
  throw new Error(error.response.data.message);
}
}
//Delete Year
export const deleteYearbyId =  async (appId,yearId) => {
  try{
    const response = await api.delete(`/api/admin/app/${appId}/year/${yearId}`);
    console.log(response.data)
    return response.data;
  }catch(error){
    throw new Error('Failed to delete question')
  }
};

//@ASSESSMENTS
//GET ASSESSMENT
//get Questions
export const fetchAssessment = async (appId) => {
  try{
    const response = await api.get(`/api/admin/app/${appId}/assessments`);
    console.log(response.data)
    return response.data;
  }catch(error){
    throw new Error('Failed to fetch Assessment')
  }
};

//Update Assessment
export const updateAsssessment =  async (appId,assessmentId, assessmentData ) => {
  try{
    const response = await api.put(`/api/admin/app/${appId}/updateassessment/${assessmentId}`, assessmentData);
    console.log(response)
    return response;
  }catch(error){
    throw new Error('Failed to update assessment')
  }
};

//@Informations
//get years
export const getInformations = async (appId) => {
  const response = await api.get(`/api/admin/app/${appId}/informations`);
 console.log(response.data)
  return response.data;
};
//create course
export const createInformation = async (appId, informationData) => {
try {
  const response = await api.post(`/api/admin/app/${appId}/informations`, informationData);
  console.log(response.data)
  return response.data;
} catch (error) {
  console.error(error);
  throw new Error('Failed to create Infomation.');
}
}
export const deleteInformationbyId =  async (appId,informationId) => {
  try{
    const response = await api.delete(`/api/admin/app/${appId}/information/${informationId}`);
    console.log(response.data)
    return response.data;
  }catch(error){
    throw new Error('Failed to delete information')
  }
};

//UpdateCategory
export const UpdateInformation =  async (appId, informationId,informationData ) => {
  try{
    const response = await api.put(`/api/admin/app/${appId}/information/${informationId}`,informationData);
    console.log(response)
    return response;
  }catch(error){
    throw new Error('Failed to update information')
  }
};
//@USER
//user Routes
export const getUsers = async (appId) => {
  try{
    const response = await api.get(`/api/admin/app/${appId}/users`);
    console.log(response.data)
     return response.data;
  }catch(error){
    console.error(error);
    throw new Error('Failed to get users.');
  }
};

export const getRegisteredUsers = async (appId) => {
  try{
    const response = await api.get(`/api/app/${appId}/registered-users`);
    console.log(response.data)
     return response.data.users;
  }catch(error){
    console.error(error);
    throw new Error('Failed to get users.');
  }
};
//Activation

export const takeActivationCode = async (appId, code) => {
  try {
    const response = await api.put(`/api/app/${appId}/activate-code`, code);
    console.log(response.data)
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('Failed to get codes.');
  }
};
//get codes
export const getAcivationCodes = async (appId) => {
  try{
    const response = await api.get(`/api/admin/app/${appId}/activation-codes`);
    console.log(response.data)
     return response.data;
  }catch(error){
    console.error(error);
    throw new Error('Failed to get codes.');
  }
};
//create code
export const createActivationCode = async (appId, codeCount) => {
try {
  const response = await api.post(`/api/admin/app/${appId}/activate`,codeCount);
  console.log('Upload successful:', response);
  return response.data;
} catch (error) {
  console.error(error);
  throw new Error('Failed to create course.');
}
}
