import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import {
  Card,
  Table,
  TableContainer,
  TablePagination,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  TextField,
  Button,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
// API
import { fetchCodeAsync, createCodeAsync, takeCodeAsync } from '../redux/reducer/activation/codeSlice';

const Activation = () => {
  const { appId } = useParams();
  const dispatch = useDispatch();

  const [count, setCount] = useState(1);
  const codes = useSelector((state) => state.code.list);
  const loading = useSelector((state) => state.code.loading);
  const [page, setPage] = React.useState(0);

  const [search, setSearch] = useState('');

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    dispatch(fetchCodeAsync(appId));
  }, [dispatch, appId]);

  const rowsPerPage = 8;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const filteredCodes = codes.filter((item) => item.email === null ? ''.includes(search.toLowerCase()) : item.email.toLowerCase().includes(search.toLowerCase())) ;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredCodes.length - page * rowsPerPage);

  const handleGenerateCodes = () => {
    try {
      const codeCount = {
        count,
      };
      dispatch(createCodeAsync(appId, codeCount));
      console.log(`${appId},${count}, ${codeCount}`);
      dispatch(fetchCodeAsync(appId));
      setCount(1);
    } catch (error) {
      console.log(error);
    }
    dispatch(fetchCodeAsync(appId));
  };

  const onTakeCode = (code) => {
    try {
      const codeData = {
        code,
      };
      dispatch(takeCodeAsync(appId, codeData));
      console.log(`${appId},${code}, ${codeData}`);
      dispatch(fetchCodeAsync(appId));
    } catch (error) {
      console.log(error);
    }
  }


  // Sort the codes based on the 'activated' property (Not Activated first)
  const sortedCodes = filteredCodes.slice().sort((a, b) => (a.activated ? 1 : -1));

  // const data = {
  //   nodes: sortedCodes.filter((item) =>
  //     item.email.includes(search.toLowerCase())
  //   ),
  // };

  console.log(sortedCodes);

  return (
    <Box>
      <Sidebar />
      <Box style={{ display: 'flex', gap: '6px', alignItems: 'center', justifyContent: 'center', padding: '1rem' }}>
        <TextField type="number" label="number of codes" value={count} required onChange={(e) => setCount(Number(e.target.value))} />
        <Button variant="contained" color="secondary" onClick={handleGenerateCodes}>
          Generate code
        </Button>
      </Box>

      <label htmlFor="search">
        Search by User:
        <input id="search" type="text" onChange={handleSearch} />
      </label>

      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow style={{ borderBottom: '1px solid #8a898f' }}>
                <TableCell>S/N</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Taken</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <CircularProgress /> {/* Display loader while fetching data */}
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {(rowsPerPage > 0 ? sortedCodes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedCodes).map((code, index) => (
                    <TableRow key={code._id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{code.code}</TableCell>
                      <TableCell>{code.activated ? 'Activated' : 'Not Activated'}</TableCell>
                      <TableCell>{code.taken ? 'Taken' : 'Not Taken'}</TableCell>
                      <TableCell>{code.email}</TableCell>
                      <TableCell>
                        <Button variant='contained' style={{ background: "red" }} onClick={() => onTakeCode(code.code)}>
                          Take
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={2} />
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={filteredCodes.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </Card>
    </Box>
  );
};

export default Activation;
