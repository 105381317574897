import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { fetchCourseAsync } from '../redux/reducer/courses/courseSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { fetchTopicsByCourseIdAsync, deleteTopicAsync, updateTopicAsync, createTopicAsync } from '../redux/reducer/topics/topicSlice';
import Sidebar from './Sidebar';
import Box from '@mui/material/Box';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import ReportIcon from '@mui/icons-material/Report';
import axios from 'axios';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
//modal
import { Modal, Card, CircularProgress, Typography, Select, TextField, MenuItem, Button }  from "@mui/material";
import { fetchCategoryAsync, updateCategoryAsync, } from '../redux/reducer/category/categorySlice';
const Topics = () => {

  const { appId } = useParams();
  const dispatch = useDispatch();

  const courses = useSelector((state) => state.course.list);
  const topics = useSelector((state) => state.topic.list);
  const loading = useSelector((state) => state.topic.loading);


  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState(null);
  const categories = useSelector((state) => state.category.list);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [instructorName, setInstructorName] = useState('');
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [SelectedTopic, setSelectedTopic] = useState(null);
  const [SelectedCategory, setSelectedCategory] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [saved, setSaved] = useState(false); // New state for saved
  // const [editedCategory, setEditedCategory] = useState(null);

  const handleOpen = () => {
    setOpen(true);
    setSaved(false)
  };

  const handleClose = () => {
    setOpen(false);
    setSaved(false)
    setTitle('')
    setDescription('')
    setInstructorName('')
    setSelectedImage('')
  };

  const openEditModal = (topic) => {
    setIsEditModalOpen(true);
    setSelectedTopic(topic);
    console.log(topic);
    var category = categories.find((cat) => cat._id == topic.category);
    console.log(category);
    if(category){
      console.log('CATEGORY FOUND');
      setCategory(category);
    }else {
      setCategory(null);
    }

  };

  // Function to close the edit modal
  const closeEditModal = () => {
    setSelectedTopic(null)
    setIsEditModalOpen(false);
  };


  const handleOpenDeleteModal = (topic) => {
    setSelectedTopic(topic);
    setOpenDeleteModal(true);
  };

  const chooseCategory = () => {
    setSelectedCategory(category._id)
    console.log('category._id')
    console.log(category._id)
    console.log(category)
  }
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };


  useEffect(() => {
    dispatch(fetchCourseAsync(appId));
  }, [dispatch, appId]);

  useEffect(() => {
    dispatch(fetchCategoryAsync(appId));
  }, [dispatch, appId]);

  useEffect(() => {
    if (selectedCourse) {
      // Fetch the list of topics when the selected course changes
      dispatch(fetchTopicsByCourseIdAsync(appId, selectedCourse));
    }
  }, [selectedCourse]);

  if (!courses) {
    return <Box>Loading...</Box>;
  }

  //selecting a course
  const handleCourseSelection = (courseId) => {
    setSelectedCourse(courseId);
    console.log(courseId);
  };

  const getSelectedCourseName = () => {
    const course = courses.find((course) => course._id === selectedCourse);
    return course ? course.name.substring(0, 20) + '...' : 'Select a Course';
  };

  const getSelectedCourseCode = () => {
    const course = courses.find((course) => course._id === selectedCourse);
    return course ? course.code : 'Select a Course';
  };


  const handleAdd = async (e) => {
    e.preventDefault()

    if (!(title && description && instructorName)) {
      alert('Please input the required fields')
      return
    }
    if (selectedImage) {
      const formData = new FormData();
      formData.append('file', selectedImage);
      formData.append('upload_preset', 'istudy');

      try {
        const response = await axios.post(
          'https://api.cloudinary.com/v1_1/istudyapp/image/upload',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        // Handle the response from Cloudinary after successful upload
        const instructorImage = response.data.secure_url
        const topicData = {
          title,
          description,
          instructorName,
          instructorImage
        }
        dispatch(createTopicAsync({ appId, categoryId: category._id, courseId: selectedCourse, topicData }))
          .then(() => {
            dispatch(fetchTopicsByCourseIdAsync(appId,  selectedCourse ));
            setSaved(true);
            setSelectedImage(null)
            setCategory(null);
            closeEditModal();
          })

      } catch (error) {
        console.error(error);
      }
    } else {
      const topicData = {
        title,
        description,
        instructorName,
      }
      dispatch(createTopicAsync({ appId, categoryId: category._id, courseId: selectedCourse, topicData }))
        .then(() => {
          dispatch(fetchTopicsByCourseIdAsync(appId,  selectedCourse ));
          setSaved(true);
          setSelectedImage(null)
          setCategory(null);
          closeEditModal();
        })
    }
  };

  //Edit Topic
  const handleUpdateTopic = async (e) => {
    e.preventDefault();
    if (SelectedTopic) {
      if (selectedImage) {
        
        const formData = new FormData();
        formData.append('file', selectedImage);
        formData.append('upload_preset', 'istudy');

        try {
          const response = await axios.post(
            'https://api.cloudinary.com/v1_1/istudyapp/image/upload',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );

          // Handle the response from Cloudinary after successful upload
          SelectedTopic.instructorImage = response.data.secure_url
          dispatch(updateTopicAsync({ appId,  categoryId: category._id, courseId: selectedCourse, topicId: SelectedTopic._id, topicData: SelectedTopic }))
            .then((updatedTopic) => {
              if (updatedTopic) {
                // Update the 'notes' state variable with the updated note data
                dispatch(fetchTopicsByCourseIdAsync(appId,  selectedCourse ));
                setSaved(true);
                setSelectedImage(null)
                setCategory(null);
                closeEditModal();
              }
            }).catch((error) => {
              console.error(error);
              // Handle error if necessary
            });

        } catch (err) {
          console.error(err);
        }
      } else {
        dispatch(updateTopicAsync({ appId, categoryId: category._id, courseId:  selectedCourse, topicId: SelectedTopic._id, topicData: SelectedTopic }))
          .then((updatedTopic) => {
            if (updatedTopic) {
              // Update the 'notes' state variable with the updated note data
            
              dispatch(fetchTopicsByCourseIdAsync(appId,  selectedCourse ));
              setSaved(true);
              setSelectedImage(null)
              setCategory(null);
              closeEditModal();
              
            }
          }).catch((error) => {
            console.error(error);
            // Handle error if necessary
          });

      }

      // dispatch(fetchTopicsByCourseIdAsync({ appId, courseId: selectedCourse }));
    }
    // dispatch(fetchTopicsByCourseIdAsync({ appId, courseId: selectedCourse }));
  }

  const handleDeleteTopic = () => {
    if (SelectedTopic) {
      dispatch(deleteTopicAsync({ appId, courseId: selectedCourse, topicId: SelectedTopic }));
      console.log(`deleted ${SelectedTopic} ${selectedCourse}`)
      dispatch(fetchTopicsByCourseIdAsync(appId, selectedCourse));
      handleCloseDeleteModal();
    }
    dispatch(fetchTopicsByCourseIdAsync(appId, selectedCourse));
  };

  const handleSelectCategory = (event) => {
   console.log(event.target.value);
   setCategory(event.target.value);
  };

  return (
    <Box>
      <Sidebar />
      <Box>
        <Typography variant='h5' fontWeight="700">Topics</Typography>
        <Box>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" align="center" style={{ marginTop: "0.3rem", marginBottom: "1rem", fontWeight: "600", border: "none", background: "#9c27b0" }}>
              {getSelectedCourseName()}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ fontSize: "13px" }}>
              {courses.map((courseId) => (
                <Dropdown.Item
                  key={courseId._id}
                  onClick={() => {
                    handleCourseSelection(courseId._id);
                  }}
                  href="#">{courseId.name}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Card>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow style={{ borderBottom: "1px solid #8a898f" }}>
                    <TableCell>S/N</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Instructor Name</TableCell>
                    <TableCell>instructor Image</TableCell>
                    <TableCell colSpan="2">Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {!selectedCourse ? (
                    <TableRow>
                      <TableCell align="center" colSpan={5}>
                        <Typography>Select a course to see topics</Typography>
                      </TableCell>
                    </TableRow>
                  ) : loading ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <CircularProgress /> {/* Render a loader while fetching */}
                      </TableCell>
                    </TableRow>
                  ) : (
                    topics.map((topic, index) => (
                      <TableRow key={topic._id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{topic.title}</TableCell>
                        <TableCell>{topic.description}</TableCell>
                        <TableCell>{topic.instructorName}</TableCell>
                        <TableCell><img src={topic.instructorImage} style={{ height: "70px", width: "70px", borderRadius: "50%" }} /></TableCell>
                        <TableCell>
                          <Button variant='contained' style={{ background: "blue" }} onClick={() => openEditModal(topic)}>
                            <EditCalendarIcon background="blue" />
                          </Button>&nbsp;
                          <Button variant='contained' style={{ background: "red" }} onClick={() => handleOpenDeleteModal(topic._id)}>
                            <DeleteForeverIcon background="red" />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                  {!loading && topics.length === 0 && selectedCourse && ( // Render "No topics" message if there are no topics and loading has finished
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <Typography>No topic for this course.</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>

          {selectedCourse && (
            <Box marginTop="1rem">
              <Button onClick={handleOpen} variant="contained" color="primary">
                Create Topic for Course {getSelectedCourseCode()}
              </Button>
              <Modal open={open} onClose={handleClose}>
                <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, backgroundColor: 'white', borderRadius: 8, padding: 16 }}>
                  <Typography variant="h6">Create Topic for {getSelectedCourseCode()}</Typography>
                  <TextField required label="title" name="title" fullWidth margin="normal" value={title} onChange={(e) => setTitle(e.target.value)} />
                  <TextField required label="Description" name="description" fullWidth margin="normal" value={description} onChange={(e) => setDescription(e.target.value)} />
                  <TextField required label="Instructor Name" name="instructorName" fullWidth margin="normal" value={instructorName} onChange={(e) => setInstructorName(e.target.value)} />
                  <Typography>Course Category</Typography>
                  <Select
                    id="answer"
                    value={category} style={{ marginTop: "1rem", marginBottom: "1rem", minWidth: "100PX" }}
                    onChange={handleSelectCategory}
                  >
                    {categories.map((category, index) => (
                      <MenuItem key={index} value={category}>
                        {category.category}
                      </MenuItem>
                    ))}
                  </Select>
                  <input type="file" accept="image/*"
                    style={{ margin: "0.8rem" }}
                    onChange={handleImageChange} />
                  <Box style={{ display: "flex", gap: "6px", alignItems: "center", justifyContent: "center", paddingBottom: "1rem" }}>
                    <Button variant="contained" color="secondary" onClick={handleAdd}>
                      {loading ? 'Saving...' : saved ? 'Saved Successfully' : 'save'}
                    </Button>
                    <Button variant="contained" style={{ background: "red" }} onClick={handleClose}>
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Box>
          )}
        </Box>

        {/**Edit Modal */}
        {/**Edit */}
        {SelectedTopic && (
          <Modal open={isEditModalOpen} onClose={closeEditModal}>
            <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, backgroundColor: 'white', borderRadius: 8, padding: 16 }}>
              <Typography variant="h6" style={{ marginBottom: "0.5rem" }}>Edit Topic</Typography>
              <TextField
                value={SelectedTopic.title}
                label="Title"
                margin="normal"
                fullWidth
                onChange={(e) => setSelectedTopic({ ...SelectedTopic, title: e.target.value })}
              />
              <TextField
                value={SelectedTopic.description}
                label="Name"
                margin="normal"
                fullWidth
                onChange={(e) => setSelectedTopic({ ...SelectedTopic, description: e.target.value })}
              />
              <TextField
                value={SelectedTopic.instructorName}
                label="Instructor Name"
                margin="normal"
                fullWidth
                onChange={(e) => setSelectedTopic({ ...SelectedTopic, instructorName: e.target.value })}
              />

              <Select
                id="answer"
                value={category}
                style={{
                  marginTop: '1rem',
                  marginBottom: '1rem',
                  minWidth: '100PX',
                }}
                onChange={handleSelectCategory}
              >
                {categories.map((category, index) => (
                  <MenuItem key={index} value={category} >
                    {category.category}
                  </MenuItem>
                ))}
              </Select>
              <Box>
                <img src={SelectedTopic.instructorImage} alt="instructor picture" style={{ borderRadius: "50%", width: 100, height: 100 }} />
                <input type="file" accept="image/*" style={{ margin: "0.8rem" }} onChange={handleImageChange} />
              </Box>
              <Box style={{ display: "flex", gap: "6px", alignItems: "center", justifyContent: "center", margin: "1rem" }}>
                <Button variant="contained" style={{ background: "#540a61" }} onClick={handleUpdateTopic}>
                  {loading ? 'Saving...' : saved ? 'Saved Successfully' : 'Update'}
                </Button>
                <Button variant="contained" style={{ background: "red", marginLeft: "1rem" }} onClick={closeEditModal}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Modal>
        )}

        {/**Delete Modal */}
        <Modal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          aria-labelledby="delete-confirmation-modal"
          aria-describedby="delete-confirmation-modal-description"
        >
          <Box style={{
            position: 'absolute',
            top: '50%', left: '50%',
            transform: 'Translate(-50%, -50%)',
            width: 400,
            backgroundColor: 'white',
            borderRadius: 8, padding: 16
          }}>
            <Box align="center">
              <ReportIcon />
              <Typography fontSize="16px" fontWeight="600" id="delete-confirmation-modal-description">
                Are you sure you want to delete this topic?
              </Typography>
            </Box>
            <Box style={{ display: "flex", gap: "6px", alignItems: "center", justifyContent: "center", margin: "1rem" }}>
              <Button variant='contained' onClick={handleDeleteTopic}>Yes,Delete</Button>
              <Button variant='contained' color='secondary' onClick={handleCloseDeleteModal}>Cancel</Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Box>
  )
}

export default Topics

