import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginAdmin } from './api';
import { fetchAppsAsync } from '../apps/appSlice';
import axios from 'axios';


const initialState = {
  isAuthenticated: false,
  user: '',
  userRole:"",
  token:localStorage.getItem('token'),
  registerSuccess: false,
  registerError: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    login: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.token = action.payload.token;
      state.user=action.payload.user
    },
    fetchUserProfile:(state,action)=>{
      state.userRole = action.payload.role;
    },
    setUserRole:(state,action)=>{
        state.userRole = action.payload.userRole;
    },
    logout: (state) => {
      state.loading = false;
      localStorage.removeItem("token")
      state.token = '';
      state.userRole = '';
      state.isAuthenticated = false;
      state.user = null;
    },
    registerSuccess: (state) => {
      state.loading = false;
      state.registerSuccess = true;
      state.registerError = '';
    },
    registerError: (state, action) => {
      state.loading = false;
      state.registerSuccess = false;
      state.registerError = action.payload;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {setUserRole, login, logout,fetchUserProfile,clearToken, registerSuccess, registerError, } = authSlice.actions;
export default authSlice.reducer;

export const fetchUserData = createAsyncThunk(
  'user/fetchUserData',
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get('/api/user', {
        headers: { Authorization: `Bearer ${token}` },
      });
      return { userData: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const loginUser = createAsyncThunk(
  '/auth/loginUser',
  async(credentials)=>{
    const response = await axios.post('/api/login', credentials);
    return response.data;
  }
)

// export const  loginAdminAsync = (loginData) => async (dispatch) => {
//   console.log('Called login Admin Async');
//   try {
//     dispatch(setLoading());
//     const data = await loginAdmin(loginData);
//     console.log(data.user.name);
//     if (data.user.role === 'admin' || data.user.role === 'instructor') {
//       localStorage.setItem('token', data.token);
//       localStorage.setItem('user', JSON.stringify(data.user));
//       localStorage.setItem('isAuthenticated', true);
//       dispatch(login({ isAuthenticated: true, token: data.token, user: JSON.stringify(data.user) }));
//       dispatch(setUserRole({ userRole: data.user.role }));
//       localStorage.setItem('userRole', data.user.role);

//       // // Fetch apps immediately after successful login
//       // dispatch(fetchAppsAsync());
     
//     } else {
     
//       dispatch(setError(`You are unauthorized to access this application ${data.user.name}`));
//     }
//   } catch (error) {
//     dispatch(setError(error.message));
//   }
// };