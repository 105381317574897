import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import {Modal, Typography, TextField, Button } from "@mui/material";
import { createAppAsync } from "../../redux/reducer/apps/appSlice";
import { fetchAppsAsync } from "../../redux/reducer/apps/appSlice";
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
const AppModal = () => {

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
  
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
      dispatch(fetchAppsAsync())
    };
  
    const handleTitleChange = (event) => {
      setTitle(event.target.value);
    };
    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
      };
    const handleAdd = () => {
      const appData ={
        title,description
      }
      dispatch(createAppAsync(appData));
      dispatch(fetchAppsAsync())
      setTitle('')
      setDescription('')
      handleClose();
    };
   
  return (
    <Box style={{padding:"2rem"}}>
    <Button align="center" variant="contained" style={{marginTop:"0.3rem",marginBottom:"1rem"}} color="secondary"  onClick={handleOpen}>
     <AddIcon />Create App
    </Button>
    <Modal open={open} onClose={handleClose}>
      <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, backgroundColor: 'white', borderRadius: 8, padding: 16 }}>
        <Typography variant="h6">Create App</Typography>
        <TextField required  label="Title" name="title" fullWidth margin="normal" value={title} onChange={handleTitleChange} />
        <TextField required  label="Description" name="description" fullWidth margin="normal" value={description} onChange={handleDescriptionChange} />
        <Button variant="contained" style={{background:"#540a61"}} onClick={handleAdd}>
          Submit
        </Button>
      </Box>
    </Modal>
  </Box>
  )
}

export default AppModal