import { createSlice } from '@reduxjs/toolkit';
import { getInformations,UpdateInformation,deleteInformationbyId,createInformation, } from '../auth/api';

const informationSlice = createSlice({
  name: 'information',
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setInformation: (state, action) => {
      state.loading = false;
      state.error = null;
      state.list = action.payload;
    },
    createInfomationSuccess:(state,action)=>{
        state.list.push(action.payload);
        state.loading = false;
        state.error = null;
    },
    updateInformationSuccess: (state, action) => {
      const { informationId, updatedInformation } = action.payload;
      const updatedList = state.list.map((information) => {
        if (information._id === informationId) {
          return updatedInformation;
        }
        return information;
      });
      state.list = updatedList;
      state.loading = false;
      state.error = null;
    },
  deleteInformation: (state, action) => {
    state.list = state.list.filter((information) => information._id !== action.payload);
    state.loading = false;
    state.error = null;
  },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setLoading, setInformation, createInformationSuccess,updateInformationSuccess,deleteInformation,setError } = informationSlice.actions;

export const fetchInformationAsync = (appId) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const informations = await getInformations(appId);
    dispatch(setInformation(informations));
  } catch (error) {
    dispatch(setError(error.message));
  }
};
export const createInformationAsync = (appId,informationData) => async (dispatch) => {
    try {
      dispatch(setLoading());
      const information = await createInformation(appId, informationData);
      dispatch(createInformationSuccess(information));
    } catch (error) {
      dispatch(setError(error.message));
    }
  };
  export const deleteInformationAsync = ({ appId,informationId}) => async (dispatch) => {
    try {
      dispatch(setLoading());
      const information = await deleteInformationbyId(appId,informationId);
      console.log(information)
      dispatch(deleteInformation(information));
    } catch (error) {
      dispatch(setError(error.message));
    }
  }; 
  export const updateInformationAsync = ({ appId, informationId, informationData }) => async (dispatch) => {
    try {
      dispatch(setLoading());
      const updatedInformation = await UpdateInformation(appId, informationId, informationData);
      // Dispatch the updated assessment to update the state
      dispatch(updateInformationSuccess(updatedInformation));
    } catch (error) {
      dispatch(setError(error.message));
    }
  };
export default informationSlice.reducer;
