import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchAppsAsync } from '../redux/reducer/apps/appSlice';
import AppsList from './components/AppList';
import AppModal from './components/AppModal';
import Sidebar from './components/Sidebar';
import { Box } from '@mui/material';

const InstructorHome = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchAppsAsync());
    }, [dispatch]);

    return (
        <Box>
            <Sidebar />
                <Box>
                  <AppsList />
                </Box>
        </Box>
    )
}

export default InstructorHome;