import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Sidebar from './Sidebar';
import { Modal, Card, Typography, TextField, Button, Alert } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import ReportIcon from '@mui/icons-material/Report';

// APIs
import { fetchCategoryAsync, deleteCategoryAsync, createCategoryAsync, updateCategoryAsync } from '../redux/reducer/category/categorySlice';
import { setError } from '../redux/reducer/category/categorySlice';

const Categories = () => {
  const { appId } = useParams();
  const dispatch = useDispatch();

  const [category, setCategory] = useState('');
  const [open, setOpen] = useState(false);
  const [saved, setSaved] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const categories = useSelector((state) => state.category.list);
  const loading = useSelector((state) => state.category.loading);
  const ErrorState = useSelector((state) => state.category.error);

  // New state variables for success and error alerts
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);

  useEffect(() => {
    dispatch(fetchCategoryAsync(appId));
  }, [dispatch, appId]);

  const handleOpen = () => {
    setOpen(true);
    setSaved(false);
  };

  const handleClose = () => {
    setOpen(false);
    setSaved(false);
    setSaving(false);
    setCategory('');
  };

  const openEditModal = (category) => {
    setIsEditModalOpen(true);
    setSelectedCategory(category);
  };

  const closeEditModal = () => {
    setSelectedCategory(null);
    setIsEditModalOpen(false);
  };

  const handleOpenDeleteModal = (category) => {
    setSelectedCategory(category);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleAdd = () => {
    setSaving(true);
    if (!category) {
      dispatch(setError('Input the required field'));
      setSaving(false);
      return;
    }
    try {
      const categoryData = {
        category
      };
      dispatch(createCategoryAsync(appId, categoryData))
        .then(() => {
          setSaving(false);
          dispatch(fetchCategoryAsync(appId));
          setSuccessAlert(true); // Show success alert
          setCategory('');
        })
        .catch((error) => {
          setSaving(false);
          setSaved(false);
          dispatch(setError(error));
          setErrorAlert(true); // Show error alert
          console.error(error);
        });
    } catch (error) {
      setSaving(false);
      setSaved(false);
      dispatch(setError(error));
      setErrorAlert(true); // Show error alert
      console.log(error);
    }
  };

  const handleDeleteCategory = () => {
    if (selectedCategory) {
      dispatch(deleteCategoryAsync({ appId, categoryId: selectedCategory }));
      console.log(`deleted ${selectedCategory}`);
      dispatch(fetchCategoryAsync(appId));
      handleCloseDeleteModal();
    }
    dispatch(fetchCategoryAsync(appId));
  };

  const handleUpdateCategory = async (event) => {
    event.preventDefault();

    if (selectedCategory) {
      console.log(selectedCategory);
      dispatch(updateCategoryAsync({ appId, categoryId: selectedCategory._id, categoryData: selectedCategory }))
        .then((updatedCategory) => {
          if (updatedCategory) {
            setSelectedCategory(updatedCategory);
            dispatch(fetchCategoryAsync(appId));
            setSaved(true);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      dispatch(fetchCategoryAsync(appId));
    }
    dispatch(fetchCategoryAsync(appId));
  };

  if (!categories) {
    return <Box><CircularProgress /> Loading...</Box>;
  }

  return (
    <Box>
        <Sidebar />
         {/* Success Alert */}
      {successAlert && (
        <Alert severity="success" onClose={() => setSuccessAlert(false)}>
          Command went successfully!
        </Alert>
      )}

      {/* Error Alert */}
      {ErrorState && (
        <Alert severity="error" onClose={() => setErrorAlert(false)}>
          {ErrorState}
        </Alert>
      )}
        <Box>
        <Typography variant='h5' fontWeight="700">Categories</Typography>
          <Button variant='contained' color='secondary' style={{display:"flex",marginLeft:"1rem",marginBottom:"3rem"}} onClick={handleOpen}><AddIcon/> Category</Button>

        <Box>
          <Modal open={open} onClose={handleClose}>
              <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, backgroundColor: 'white', borderRadius: 8, padding: 16 }}>
                <Typography variant="h6">Create Category</Typography>
              <Box style={{padding:"0.2rem"}}>
              {ErrorState && <Alert variant='danger'>{ErrorState}</Alert>}
              </Box>
                <TextField type='text' label="Category" name="year" fullWidth margin="normal" value={category}  onChange={(e) => setCategory(e.target.value)} />
              <Box style={{display:"flex",gap:"6px",alignItems:"center",justifyContent:"center",paddingBottom:"1rem"}}>
              <Button variant="contained" style={{background:"#540a61"}} onClick={handleAdd}>
              {saving ? 'Saving...' : saved ? 'Saved Successfully' : 'Save'}
                </Button>
                <Button variant='contained' onClick={handleClose} style={{background:"red"}}>Cancel</Button>
              </Box>
              </Box> 
             </Modal>
            </Box>
            <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow style={{ borderBottom: "1px solid #8a898f" }}>
                <TableCell>S/N</TableCell>
                <TableCell>Course Category</TableCell>
                <TableCell colSpan="2">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : categories && categories.length === 0 ? ( // Check if categories is an array
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <Typography variant="subtitle1">Oops, no category available.</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                categories.map((category, index) => (
                  <TableRow key={category._id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{category.category}</TableCell>
                    <TableCell>
                      <Button variant='contained' style={{ background: "#2f68df" }} onClick={() => openEditModal(category)}><EditCalendarIcon /> </Button> &nbsp;
                      <Button variant='contained' style={{ background: "red" }} onClick={() => handleOpenDeleteModal(category._id)}><DeleteForeverIcon background="red" /> </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
        </Box>

        {/**Edit Category */}

        
        {selectedCategory && (
        <Modal open={isEditModalOpen} onClose={closeEditModal}>
            <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, backgroundColor: 'white', borderRadius: 8, padding: 16}}>  
            <Typography variant="h6" style={{marginBottom:"0.5rem"}}>Edit Course Category</Typography>
            <TextField 
            value={selectedCategory.category} 
            label="Course Category"
            margin="normal"
            fullWidth
            onChange={(e) => setSelectedCategory({ ...selectedCategory, category: e.target.value })}
            />      
           <Box style={{display:"flex",gap:"6px",alignItems:"center",justifyContent:"center",margin:"1rem"}}>
           <Button variant="contained" style={{background:"#540a61"}} onClick={handleUpdateCategory}>
                 {loading ? 'Saving...' : saved ? 'Saved Successfully' : 'Save'}
              </Button>
              <Button variant="contained" style={{background:"red",marginLeft:"1rem"}} onClick={closeEditModal}>
                  Cancel
              </Button>
           </Box>
            </Box>
        </Modal>
        )}


        {/**Delete Category */}
        <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-confirmation-modal"
        aria-describedby="delete-confirmation-modal-description"
      >
        <Box style={{ 
          position: 'absolute', 
          top: '50%', left: '50%',
          transform: 'Translate(-50%, -50%)', 
          width: 400,
          backgroundColor: 'white', 
          borderRadius: 8, padding: 16 }}>
          <Box align="center">
            <ReportIcon/>
            <Typography fontSize="16px" fontWeight="600" id="delete-confirmation-modal-description">
              Are you sure you want to delete this category?
            </Typography>
          </Box>
          <Box style={{display:"flex",gap:"6px",alignItems:"center",justifyContent:"center",margin:"1rem"}}>
          <Button variant='contained' onClick={handleDeleteCategory}>Yes,Delete</Button>
          <Button variant='contained' color='secondary' onClick={handleCloseDeleteModal}>Cancel</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default Categories